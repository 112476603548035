import React, { useState } from "react";
import Autocomplete from "react-google-autocomplete";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { transparentize, darken, lighten } from "polished";
import Checkbox from "rc-checkbox";
import Switch from "react-switch";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { Theme } from "./theme";
import { StringComp, LabelWidth } from "./typo";
import Uploader from "./upload";
import { GoogleAPIKey } from "../util/constants";
import { Grid } from '@flexisaf/flexibull2';



export const InputWrapper = styled.div`
  position: relative;
  & input {
    display: block;
    position: relative;
    z-index: 0;
    width: 100%;
    padding: 0 10px;
    height: 36px;
    border: 1px solid ${Theme.PrimaryInputOutline};
    border-radius: ${Theme.PrimaryRadius};
    &:focus {
      outline: none;
      border: 1px solid ${Theme.PrimaryColor};
      box-shadow: 0 0 3px ${transparentize(0.3, Theme.PrimaryColor)};
    }
    &::placeholder {
      color: ${darken(0, Theme.PrimaryInputOutline)};
    }
    &:disabled {
      background: ${transparentize(0.8, Theme.PrimaryInputOutline)};
      border: 1px dashed ${darken(0.1, Theme.PrimaryInputOutline)};
    }
    ${(props: any) =>
      props.error &&
      css`
        border: 1px solid ${Theme.PrimaryRed};
        box-shadow: 0 0 2px ${transparentize(0.6, Theme.PrimaryRed)};
      `}
  }

  & .type {
    display: inline-flex;
    position: absolute;
    height: 36px;
    z-index: 1;
    width: 36px;
    bottom: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    color: ${darken(0.1, Theme.PrimaryInputOutline)};
  }
`;

export const InputLabel = styled.div`
  height: 20px;
  font-size: 9px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${lighten(0.2, Theme.PrimaryFontColor)};
`;
export const InputError = styled.div`
    font-size: ${Theme.FontSize_s};
    font-weight: lighter;
    font-style: italic;
    /* color: ${(props: any) => props.color}; */
    text-align: right;
    position: absolute;
    width: 100%;
    bottom: -19px;
    right: 0;
    color: ${transparentize(0.2, Theme.PrimaryRed)};
`;

const InputIcon = (type: any) => {
  switch (type) {
    case "search":
      return ["icon-search", type];
    case "number":
      return ["icon-sort-numeric", type];
    case "location":
      return ["icon-globe", type];
    case "plain":
      return ["", "text"];
    case "money":
      return ["icon-dollar", "text"];
    case "date":
      return ["", "date"];
      case "single-date":
        return ["", "single-date"];
    case "email":
      return ["icon-envelope-open-o", "text"];
    case "phone":
      return ["icon-phone-squared", "text"];
    case "password":
      return ["icon-block", type];
    default:
      return ["icon-sort-alphabet", type];
  }
};

export const Input = (props: any) => {
  return (
    <InputWrapper {...props}>
      {props.label && (
        <InputLabel>
          <StringComp>{props.label}</StringComp>
        </InputLabel>
      )}
      {props.type !== "date" && (
        <i className={`type ${InputIcon(props.type)[0]}`} />
      )}
      <input
        value={props?.value}
        data-index={props["data-index"]}
        placeholder={props?.placeholder || ""}
        required={props?.required}
        type={InputIcon(props.type)[1]}
      />
      <InputError>
        <StringComp>{props.error}</StringComp>
      </InputError>
    </InputWrapper>
  );
};


export const SingleDate = (props) => {

  return (
    <InputWrapper {...props}>
      {props.label && (
        <InputLabel>
          <StringComp>{props.label}</StringComp>
        </InputLabel>
      )}
      <Grid gap="10px" default="repeat(3, 1fr)">
      <input
        value={props?.value}
        data-index={props["data-index"]}
        placeholder={props?.placeholder || ""}
        required={props?.required}
        type={InputIcon(props.type)[1]}
        />
        <input
        value={props?.value}
        data-index={props["data-index"]}
        placeholder={props?.placeholder || ""}
        required={props?.required}
        type={InputIcon(props.type)[1]}
        />
        <input
        value={props?.value}
        data-index={props["data-index"]}
        placeholder={props?.placeholder || ""}
        required={props?.required}
        type={InputIcon(props.type)[1]}
      />
      </Grid>
      <InputError>
        <StringComp>{props.error}</StringComp>
      </InputError>
    </InputWrapper>
    
  );
};



export const ViewData = (props: any) => {
  return (
    <InputWrapper {...props}>
      {props.label && (
        <InputLabel>
          <StringComp>{props.label}</StringComp>
        </InputLabel>
      )}
      {props.type !== "date" && (
        <i className={`type ${InputIcon(props.type)[0]}`} />
      )}
      <input
        {...props}
        disabled
        value={props?.value}
        type={InputIcon(props.type)[1]}
      />
    </InputWrapper>
  );
};
export const TextWrapper = styled.div<any>`
  position: relative;
  & textarea {
    display: block;
    position: relative;
    z-index: 0;
    width: 100%;
    padding: 10px;
    height: ${(props: any) => props.height};
    border: 1px solid ${Theme.PrimaryInputOutline};
    border-radius: ${Theme.PrimaryRadius};
    &:focus {
      outline: none;
      border: 1px solid ${Theme.PrimaryColor};
      box-shadow: 0 0 3px ${transparentize(0.3, Theme.PrimaryColor)};
    }
    &::placeholder {
      color: ${darken(0, Theme.PrimaryInputOutline)};
    }
    &:disabled {
      background: ${transparentize(0.8, Theme.PrimaryInputOutline)};
      border: 1px dashed ${darken(0.1, Theme.PrimaryInputOutline)};
    }
    ${(props: any) =>
      props.error &&
      css`
        border: 1px solid ${Theme.PrimaryRed};
        box-shadow: 0 0 2px ${transparentize(0.6, Theme.PrimaryRed)};
      `}
  }
`;
export const TextArea = (props: {
  label: string;
  value: string;
  name: string;
  error: any;
  onChange: () => void;
}) => {
  return (
    <TextWrapper {...props}>
      {props.label && (
        <InputLabel>
          <StringComp>{props.label}</StringComp>
        </InputLabel>
      )}
      <textarea {...props} />
      <InputError>
        <StringComp>{props.error}</StringComp>
      </InputError>
    </TextWrapper>
  );
};
TextArea.defaultProps = {
  height: "30px",
};
TextArea.propTypes = {
  height: PropTypes.string,
  onChange: PropTypes.func,
};

export const SelectWrapper = styled.div`
    position: relative;
    width: 100%;
    & .c9__control{
        border: 1px solid ${Theme.PrimaryInputOutline};
        ${(props: any) =>
          props.solid &&
          css`
            border: none;
            background: ${(property: any) => property.solid};
          `}
        border-radius: ${Theme.PrimaryRadius};
        height: 36px;
        &:focus{
            outline: none;
            border: 1px solid ${Theme.PrimaryColor};
            box-shadow: 0 0 3px ${transparentize(0.3, Theme.PrimaryColor)};
            ${(props: any) =>
              props.solid &&
              css`
                border: none;
                background: ${(innerProp: any) =>
                  darken(0.05, innerProp.solid)};
                box-shadow: 0 0 3px
                  ${(innerProp: any) => transparentize(0.3, innerProp.solid)};
              `}
        }
        ${(props: any) =>
          props.disabled &&
          css`
            border: 1px dashed ${Theme.PrimaryInputOutline};
          `}
        &:hover{
            border: 1px solid ${Theme.PrimaryInputOutline};
            ${(props: any) =>
              props.solid &&
              css`
                border: none;
                background: ${(innerProp: any) =>
                  darken(0.05, innerProp.solid)};
                box-shadow: 0 0 1px
                  ${(innerProp: any) => transparentize(0.3, innerProp.solid)};
              `}
        }
        &.c9__control--is-focused,:focus{
            outline: none;
            border: 1px solid ${Theme.PrimaryColor};
            box-shadow: 0 0 3px ${transparentize(0.3, Theme.PrimaryColor)};
            ${(props: any) =>
              props.solid &&
              css`
                border: none;
                background: ${(innerProp: any) =>
                  darken(0.05, innerProp.solid)};
                box-shadow: 0 0 3px
                  ${(innerProp: any) => transparentize(0.3, innerProp.solid)};
              `}
        }
        &:disabled{
            background: ${transparentize(0.8, Theme.PrimaryInputOutline)};
            border: 1px dashed ${darken(0.1, Theme.PrimaryInputOutline)};
        }
        ${(props: any) =>
          props.error &&
          css`
            border: 1px solid ${Theme.PrimaryRed};
            box-shadow: 0 0 2px ${transparentize(0.6, Theme.PrimaryRed)};
          `}
        & .c9__placeholder{
            color: ${darken(0, Theme.PrimaryInputOutline)};
            ${(props: any) =>
              props.solid &&
              css`
                color: ${(innerProp: any) => darken(0.25, innerProp.solid)};
              `}
        }
        & .c9__indicators{
            ${(props: any) =>
              props.search &&
              css`
                &::after {
                  content: "\\e81a";
                  display: flex;
                  position: absolute;
                  height: 34px;
                  align-items: center;
                  font-family: "cloud9";
                  color: ${Theme.PrimaryInputOutline};
                  ${(innerProp: any) =>
                    innerProp.solid &&
                    css`
                      color: ${transparentize(0.9, "#fff")};
                    `}
                  right: 52px;
                }
              `}
            & .c9__indicator-separator{
                display: none;
            }
            & .c9__indicator.c9__dropdown-indicator{
                height: 36px;
                width: 36px;
                border-radius: 0 ${Theme.PrimaryRadius} ${
  Theme.PrimaryRadius
} 0;
                align-items: center;
                justify-content: center;
                background: ${transparentize(0.8, Theme.PrimaryInputOutline)};
                border-left: 1px solid ${Theme.PrimaryInputOutline};
                ${(props: any) =>
                  props.solid &&
                  css`
                    border-left: none;
                    background: none;
                    color: ${(innerProp: any) => darken(0.25, innerProp.solid)};
                  `}

                & svg{
                    fill:${Theme.PrimaryInputOutline};
                    width: 14px;
                    ${(props: any) =>
                      props.solid &&
                      css`
                        fill: ${(innerProp: any) =>
                          darken(0.25, innerProp.solid)};
                      `}
                }
            }
        }
    }
    & .c9__menu{
        z-index: 10;
        border-radius: ${Theme.PrimaryRadius};
        border: 1px solid ${darken(0.2, Theme.PrimaryInputOutline)};
        ${(props: any) =>
          props.solid &&
          css`
            background: ${Theme.PrimaryGreyDark};
            color: #fff;
            border: none;
          `}
        ${Theme.Elevate_2};
        overflow: hidden;

        & .c9__menu-list{
            padding: 0;

            & .c9__option{
                &.c9__option--is-focused{
                    background: ${transparentize(
                      0.9,
                      Theme.PrimaryInputOutline
                    )};
                }
                &.c9__option--is-selected{
                    background: ${darken(0.2, Theme.PrimaryInputOutline)};
                    ${(props: any) =>
                      props.solid &&
                      css`
                        background: ${(innerProp: any) =>
                          lighten(0.02, innerProp.solid)};
                      `}
                    &.c9__option--is-focused{
                        background: ${darken(0.25, Theme.PrimaryInputOutline)};
                        ${(props: any) =>
                          props.solid &&
                          css`
                            background: ${(innerProp: any) =>
                              lighten(0.02, innerProp.solid)};
                          `}
                    }
                }
            }
        }
    }
`;

export const SafSelect = (props: any) => {
  return (
    <SelectWrapper {...props}>
      {props.label && (
        <InputLabel>
          <StringComp>{props.label}</StringComp>
        </InputLabel>
      )}
      <Select
        {...props}
        isSearchable={props.search ? true : false}
        isDisabled={props.disabled ? true : false}
        className="c9_select"
        classNamePrefix="c9"
      />
      <InputError>
        <StringComp>{props.error}</StringComp>
      </InputError>
    </SelectWrapper>
  );
};
export const C9AsyncSelect = (props: any) => {
  return (
    <SelectWrapper {...props}>
      {props.label && (
        <InputLabel>
          <StringComp>{props.label}</StringComp>
        </InputLabel>
      )}
      <AsyncSelect
        {...props}
        // isSearchable={props.search ? true : false}
        // isDisabled={props.disabled ? true : false}
        className="c9_select"
        classNamePrefix="c9"
      />
      <InputError>
        <StringComp>{props.error}</StringComp>
      </InputError>
    </SelectWrapper>
  );
};

const CheckboxWrapper = styled.div`
  display: inline-block;
  margin: 5px 0 0 0;

  & input[type='checkbox'] {

  cursor: pointer;
    margin: 0px 4px;
    width: 20px;
    height: 20px;
    padding: 3px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid ${lighten(0.2, Theme.PrimaryFontColor)};
    outline: none;

    &:disabled{

  cursor: pointer;
      border: 1px solid ${lighten(0.67, Theme.PrimaryFontColor)};
      background: ${lighten(0.7, Theme.PrimaryFontColor)};
      color: ${lighten(0.5, Theme.PrimaryFontColor)};
    }

    &:checked {

  cursor: pointer;
      /* background: ${Theme.PrimaryColor}; */
      background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjE4MS4yIDI3MyAxNyAxNiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAxODEuMiAyNzMgMTcgMTYiPjxwYXRoIGQ9Ik0tMzA2LjMgNTEuMmwtMTEzLTExM2MtOC42LTguNi0yNC04LjYtMzQuMyAwbC01MDYuOSA1MDYuOS0yMTIuNC0yMTIuNGMtOC42LTguNi0yNC04LjYtMzQuMyAwbC0xMTMgMTEzYy04LjYgOC42LTguNiAyNCAwIDM0LjNsMjMxLjIgMjMxLjIgMTEzIDExM2M4LjYgOC42IDI0IDguNiAzNC4zIDBsMTEzLTExMyA1MjQtNTI0YzctMTAuMyA3LTI1LjctMS42LTM2eiIvPjxwYXRoIGZpbGw9IiMzNzM3MzciIGQ9Ik0xOTcuNiAyNzcuMmwtMS42LTEuNmMtLjEtLjEtLjMtLjEtLjUgMGwtNy40IDcuNC0zLjEtMy4xYy0uMS0uMS0uMy0uMS0uNSAwbC0xLjYgMS42Yy0uMS4xLS4xLjMgMCAuNWwzLjMgMy4zIDEuNiAxLjZjLjEuMS4zLjEuNSAwbDEuNi0xLjYgNy42LTcuNmMuMy0uMS4zLS4zLjEtLjV6Ii8+PHBhdGggZD0iTTExODcuMSAxNDMuN2wtNTYuNS01Ni41Yy01LjEtNS4xLTEyLTUuMS0xNy4xIDBsLTI1My41IDI1My41LTEwNi4yLTEwNi4yYy01LjEtNS4xLTEyLTUuMS0xNy4xIDBsLTU2LjUgNTYuNWMtNS4xIDUuMS01LjEgMTIgMCAxNy4xbDExNC43IDExNC43IDU2LjUgNTYuNWM1LjEgNS4xIDEyIDUuMSAxNy4xIDBsNTYuNS01Ni41IDI2Mi0yNjJjNS4yLTMuNCA1LjItMTIgLjEtMTcuMXpNMTYzNC4xIDE2OS40bC0zNy43LTM3LjdjLTMuNC0zLjQtOC42LTMuNC0xMiAwbC0xNjkuNSAxNjkuNS03MC4yLTcxLjljLTMuNC0zLjQtOC42LTMuNC0xMiAwbC0zNy43IDM3LjdjLTMuNCAzLjQtMy40IDguNiAwIDEybDc3LjEgNzcuMSAzNy43IDM3LjdjMy40IDMuNCA4LjYgMy40IDEyIDBsMzcuNy0zNy43IDE3NC43LTE3Ni40YzEuNi0xLjcgMS42LTYuOS0uMS0xMC4zeiIvPjwvc3ZnPg==') no-repeat center center;
      /* border: none; */
      outline: none;
      opacity: .8;
    }
  }
  .checkboxTitle{

  cursor: pointer;
    vertical-align: super;
    padding: 4px;
  }
`;

export const CheckboxComp = (props: any) => {
  return (
    <CheckboxWrapper>
      <label>
        <Checkbox {...props} />
        <span className="checkboxTitle">{props.name}</span>
      </label>
    </CheckboxWrapper>
  );
};

export const UploadComp = (props: any) => {
  const [encodedFiles, setEncodedFiles] = useState([]);
  return (
    <>
      <LabelWidth>
        <StringComp>{props?.label}</StringComp>
      </LabelWidth>
      {/* <Upload {...props}>
        <UploadButton>
          <i className="icon-doc-inv-alt"> </i>
          <span>Click to Upload</span>
        </UploadButton>
      </Upload> */}
      <Uploader mini={true} 
      config={{ accept: "image/jpeg, image/png" }}
              encodedFiles={encodedFiles}
              setEncodedFiles={setEncodedFiles}
              multiple
              type="file" />
    </>
  );
};

export const SwitchComp = (props: any) => {
  const [checked, toggleChecked] = useState(false);
  return (
    <Switch
      onColor="#699CD6"
      handleDiameter={18}
      height={14}
      width={30}
      {...props}
      checked={props?.checked || checked}
      onChange={props.onChange ? props.onChange : () => toggleChecked(!checked)}
    />
  );
};

const FontSizes: any = {
  small: "0.8rem",
  standard: "1rem",
  medium: "1.2rem",
  large: "1.6rem",
  xlarge: "2rem",
};

export const Label = styled.span<any>`
  font-size: ${(props) => (props.size ? FontSizes[props.size] : "0.8rem")};
  font-weight: normal;
  font-family: ${Theme.PrimaryFont};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  padding: 0;
  color: ${(props) => (props.color ? props.color : Theme.PrimaryGrey)};
  letter-spacing: 0.45;
  text-transform: uppercase;
`;

const RadioWapper = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: ${Theme.PrimaryFontSize};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  & .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 50%;

    :after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  & :hover input ~ .checkmark {
    background-color: ${Theme.PrimaryGreyLight};
  }

  & input:checked ~ .checkmark {
    background-color: ${Theme.PrimaryLight};
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
  }
`;

export const RadioButton = (props: any) => {
  const [checked, toggleChecked] = useState(false);
  return (
    <div>
      <RadioWapper>
        {props.label}
        <input
          type="radio"
          {...props}
          checked={checked}
          value={props.value}
          name={props.name}
          onClick={() => toggleChecked(!checked)}
        />
        <span className="checkmark"></span>
      </RadioWapper>
    </div>
  );
};

export const AutoComplete = (props: any) => {
  return (
    <InputWrapper {...props}>
      {props.label && (
        <InputLabel>
          <StringComp>{props.label}</StringComp>
        </InputLabel>
      )}
      <Autocomplete
        apiKey={GoogleAPIKey}
        onPlaceSelected={(place) => console.log(place)}
        placeholder={props?.placeholder || "Enter a Location"}
        style={{ width: "100%" }}
      />
      <InputError>
        <StringComp>{props.error}</StringComp>
      </InputError>
    </InputWrapper>
  )
}