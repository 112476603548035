import axios from 'axios';
import urls from './constants';

export const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('access_token');

  window.location.reload();
};

const fetch = (options) => {
  const { method = 'get', data, url, responseType } = options;

  const authToken = localStorage.getItem('access_token');
  // eslint-disable-next-line no-unused-expressions
  authToken &&
    (axios.defaults.headers.common.Authorization = `Bearer ${authToken}`);

  switch (method.toLowerCase()) {
    case 'get':
      return axios.get(url, {
        params: data,
        responseType
      });
    case 'delete':
      return axios.delete(url, {
        data,
      });
    case 'post':
      switch (options.upload) {
        case true:
          return axios.post(url, options.formData, options.config);
        default:
          return axios.post(url, data);
      }
    case 'put':
      return axios.put(url, data);
    case 'patch':
      return axios.patch(url, data);
    case 'download':
      return axios.get(url, {
        params: data,
        responseType,
      });
    default:
      return axios(options);
  }
};

export default function request(options) {
  return fetch(options)
    .then((response) => {
      const { data, status } = response;
      return {
        success: true,
        raw: data,
        statusCode: status,
      };
    })
    .catch((err) => {
      const { response } = err;
      let msg;
      let statusCode;
      if (response && response instanceof Object) {
        const { data, statusText } = response;
        statusCode = response.status;

        msg = data.error || statusText;

        if (statusCode === 401) {
          const refresh_token = localStorage.getItem('refresh_token');
          const userDetails = JSON.parse(localStorage.getItem('user') || '');
          const payload = {
            grant_type: 'refresh_grant',
            refresh_token,
            client_secret: userDetails.secret,
            client_id: userDetails.id,
          };

          const encodeFormData = (data) =>
            Object.keys(data)
              .map(
                (key) =>
                  `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
              )
              .join('&');

          return axios
            .post(
              `${urls?.UMS_BASE_URL}/oauth2/token`,
              encodeFormData(payload),
              {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                  Authorization: 'Bearer',
                },
              }
            )
            .then((resp) => {
              const refreshData = resp.data ? resp.data : {};
              const newToken = refreshData.access_token;
              localStorage.setItem('access_token', newToken);
              return request(options);
            })
            .catch((error) => {
              const errResponse = error.response;
              const errStatus = errResponse.status;
              if (errStatus === 401 || errStatus === 404) {
                return logout();
              }
              return {
                success: false,
                statusCode: errStatus,
                message: errResponse.data
                  ? errResponse.data.message
                  : errResponse.statusText,
              };
            });
        }
      } else {
        statusCode = 600;
        msg = 'Poor internet connections.';
      }
      return { success: false, statusCode, message: msg };
    });
}
