import React from 'react'
import {
    Modal,
    ModalBody,
    ModalClose,
    Box,
    Text,
    Button,
    ModalFooter,
    Spacer,
} from "@flexisaf/flexibull2";
import FormRender from '@flexisaf/flexi-form-render';
import labelGuide from './images/label-guide.png';
import sectionGuide from './images/section-guide.png';

import '@flexisaf/flexi-form-render/dist/index.css';

export const Preview = ({ schema, open, close }) => {
    return (
        <Modal center={false} outerClick open={open} style={{ padding: 0, width: "auto" }}>
            <ModalBody width="100%" style={{ borderRadius: 0 }}>
                <ModalClose onClick={() => close()}>&times;</ModalClose>
                <Spacer space="50px" />
                {schema.layout && <FormRender disableLabels={false} styler="wizard" data={schema} />}
            </ModalBody>
        </Modal>
    )
}

export const Instruction = ({ open, close }) => {
    return (
        <Modal outerClick open={open} center={false}>
            <ModalBody width="500px">
                <Box pad="30px">
                    <Text bold block size="20px" color={({ theme }) => theme.PrimaryRed}>
                        You are not done yet
                    </Text>
                    <Box maxWidth="200px" margin="15px 0 20px 0" background={({ theme }) => theme.PrimaryRed} height="2px" />
                    <Text size="14px" block>
                        You have some incomplete components in your form, please check again.
                    </Text>
                    <Box margin="20px 0 5px 0">
                        <Text bold block size="14px">
                            Make sure all form labels are named properly
                        </Text>
                        <Spacer />
                        <img width="100%" src={labelGuide} alt="label Instruction" />
                        <Spacer space="30px" />
                        <Text bold block size="14px">
                            Make sure all section titles have been name properly
                        </Text>
                        <Spacer />
                        <img width="100%" src={sectionGuide} alt="label Instruction" />
                        <Spacer space="20px" />
                        <Text block size="14px">
                            Check the field properties to update.
                        </Text>
                    </Box>
                </Box>
                <ModalFooter>
                    <Box pad="20px 30px" align="right">
                        <Button
                            spaceRight
                            height={40}
                            color="#aaa"
                            onClick={() => close()}
                        >
                            Cancel
                        </Button>
                    </Box>
                </ModalFooter>
            </ModalBody>
        </Modal>
    )
}