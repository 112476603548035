const urls = {
  development: {
    FORM_API_BASE_URL: 'https://api.fbs.flexisafapps-dev.com',
    FORM_DATA_BASE_URL: 'https://api.fds.flexisafapps-dev.com',
    FORM_DATA_REPOSITORY: 'https://api.fds.flexisafapps-dev.com',
    UMS_BASE_URL: 'https://api.ums.flexisafapps-dev.com',
  },
  staging: {
    FORM_API_BASE_URL: 'https://api.fbs.flexisafapps-stage.com',
    FORM_DATA_BASE_URL: 'https://api.fds.flexisafapps-stage.com',
    FORM_DATA_REPOSITORY: 'https://api.fds.flexisafapps-stage.com',
    UMS_BASE_URL: 'https://api.ums.flexisafapps-stage.com',
  },
  production: {
    FORM_API_BASE_URL: 'https://form-builder-api.component.flexisaf.com',
    FORM_DATA_BASE_URL: 'https://form-builder.component.flexisaf.com',
    FORM_DATA_REPOSITORY: 'https://forms-data-api.component.flexisaf.com',
    UMS_BASE_URL: 'https://ums-api.component.flexisaf.com',
  },
};

export default urls[
  process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development'
];

export const GoogleAPIKey = 'AIzaSyA1Xo-bDgo3segKgvXXmvin79XiASGDCh0';

export const TinyMCEKey = "nwtx8785vktbbbqadu4o8ddza1s2abp1hqat5niqpjyvr1fk";
