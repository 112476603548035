import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { transparentize } from "polished";
import "./fonts/fontello/css/flexiform.css";
import "./fonts/fontello/css/animation.css";

export const GridLayout = styled("div")<any>`
  display: grid;
  grid-template-columns: ${(props) => (props.collapseLeft ? 0 : "250px")} auto ${(
      props
    ) => (props.collapseRight ? 0 : "250px")};
  grid-template-rows: 85px auto;
  min-height: 100vh;
  transition: all 0.3s ease-out;
  width: 100%;
  overflow: hidden;
`;

// GridLayout.propTypes = {
//     collapseLeft: PropTypes.bool,
//     collapseRight: PropTypes.bool,
// }

export const TopSide = styled.div`
  background: #fff;
  border-bottom: 1px solid
    ${(props) => transparentize(0.3, props.theme.PrimaryBorderColor)};
  grid-column: 1 / span 3;
  grid-row: 1;
  transition: all 0.3s ease-out;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
`;
export const RightSide = styled.div`
  /* width: 200px; */
  border-left: 1px solid
    ${(props) => transparentize(0.3, props.theme.PrimaryBorderColor)};
  /* background: #fff; */
  grid-column: 3;
  grid-row: 2;
  transition: all 0.3s ease-out;
`;
export const LeftSide = styled.div`
  /* width: 200px; */
  border-right: 1px solid
    ${(props) => transparentize(0.3, props.theme.PrimaryBorderColor)};
  grid-column: 1;
  background: #fff;
  grid-row: 2;
  overflow: hidden;
  transition: all 0.3s ease-out;
`;
export const MainPanel = styled.div`
  grid-column: 2;
  grid-row: 2;
  overflow: auto;
  height: calc(100vh);
`;

export const DragElementWrapper = styled("div")<any>`
  border-radius: ${(props) => props.theme.SecondaryRadius};
  background: ${(props) => transparentize(0.2, props.theme.PrimaryFade)};
  border: 1px solid
    ${(props) => transparentize(0.9, props.theme.PrimaryBorderColor)};
  padding: 5px;
  min-height: 40px;
  margin: 5px 0;
  display: grid;
  grid-template-columns: 30px auto 30px;
  grid-gap: 10px;
  align-items: center;
  transition: all 0.3s ease-out;
  cursor: pointer;
  &:hover {
    ${(props) => props.theme.Elevate.low};
    border: 1px solid
      ${(props) => transparentize(0.3, props.theme.PrimaryBorderColor)};
  }

  & .drag-drag {
    display: grid;
    grid-template-columns: 5px 5px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    grid-gap: 0;
    opacity: 0.3;
  }

  & .drag-icon {
    border-radius: ${(props) => props.theme.SecondaryRadius};
    background: ${(props) => transparentize(0.6, props.color)};
    display: grid;
    width: 26px;
    height: 26px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: ${(props) => props.color};
    & i {
      display: block;
    }
  }
  & .drag-label {
    display: flex;
    align-items: center;
  }
`;

export const DragElement = (props: any) => {
  return (
    <DragElementWrapper {...props}>
      <div className="drag-icon">
        <i className={props.icon} />
      </div>
      <div className="drag-label">{props.label}</div>
      <div className="drag-drag">
        <i className="form-ellipsis-vert" />
        <i className="form-ellipsis-vert" />
      </div>
    </DragElementWrapper>
  );
};
DragElement.defaultProps = {
  color: "#ffffffe6",
  label: "element",
};

DragElement.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
};
