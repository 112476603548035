import styled from "styled-components";
export const Layout = styled.div`
  width: 99vw;
  min-height: 100vh;
  background: #eee;
  display: grid;
  grid-template-columns: auto;
`;
export const SchemaBox = styled.div`
  border-right: 1px solid #ccc;
  padding: 20px;
  font-size: 10px;
  & pre {
    padding: 10px;
    white-space: pre-wrap;
  }
`;
export const ConfigBox = styled.div`
  border-left: 1px solid #ccc;
  padding: 30px;
`;
export const ConfigContainer = styled.div`
  background: #fff;
  padding: 10px 10px;
  border-left: 1px solid #ccc;
`;
export const Formcover = styled.div`
  background: #fff;
  /* padding-left: 20px; */
  padding-top: 40px;
  padding-left: 13px;
  padding-right: 13px;
  width: 20%;
  margin: 0;
  min-height: 100vh;
  height: 100%;
  border-left: 1px solid #d3d3d3;
  /* border-style: solid; */
  border-width: thin;
`;
export const HeaderButtons = styled.div`
  margin: 20px 10px 0px 0px;
`;

export const Header = styled.header`
  height: 70px;
  background-color: #ffffff;
  border-bottom: 1px solid #d3d3d3;
  border-width: thin;
  display: flex;
  flex: 1;
  justify-content: space-between;
  /* div {
    margin:20px 10px;
    padding:10px;
  } */
  img {
    width: 30px;
  }
`;
export const SubHead = styled.header`
  margin-left: 5px;
  p {
    border-bottom: 5px solid #4285f4;
    padding-bottom: 5px;
    border-width: 5px;
    font-size: 12px;
    width: 80px;
  }
  h2 {
    border-bottom: 5px solid #4285f4;
    padding-bottom: 5px;
    border-width: 5px;
    font-size: 12px;
    width: 150px;
  }
`;
export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const Button = styled.button<any>`
  padding: 6px 9px;
  border-radius: 2px;
  min-width: 70px;
  cursor: pointer;
  color: #fff;
  /* transition: background-color 0.2s linear, color 0.2s linear; */
  background: ${(props: any) =>
    props.invert ? "rgb(219, 217, 224)" : "rgb(70, 130, 230)"};
  color: ${(props: any) => (props.invert ? "#333" : "#fff")};
  margin-right: 5px;
  outline: none;
  border: none;
  border: 1px solid transparent;
  border-width: thin;
  box-shadow: 10px -9px 5px -13px rgba(0, 0, 0, 0.75);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 12px;

  &:hover {
    color: #1e5799;
    background: #ffffff;
    border: 1px solid #4682e6;
    border-width: thin;
  }
`;
export const Form = styled.div`
  background-color: white;
  padding: 35px;
  max-width: 1000px;
  margin: 50px auto;
`;
export const Row = styled.div<any>`
  margin: 5px 0;
  border: 1px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 12px;
  background-color: ${(props: any) =>
    props.active ? "#14f195c4" : "#cccccc21"};
  color: #00000073;
`;
export const Liner = styled.div<any>`
  margin: 10px 0px;
  & .layout {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(${(props) => props.row}, 1fr);
  }
`;
export const Laybutton = styled.div`
  margin-right: 15px;
  height: 20px;
  width: 26px;
  display: inline-grid;
  grid-template-columns: repeat(${(props: any) => props.children.length}, 1fr);
  grid-gap: 2px;
  cursor: pointer;
  & div {
    border-radius: 3px;
    border: 1px dashed #ccc;
    transition: 0.3s ease-out;
  }
  &:hover {
    & div {
      border: 1px solid blue;
    }
  }
`;
