import React from 'react'
import { Box, Button, Spacer} from '@flexisaf/flexibull2'
import styled from 'styled-components'
import Webcam from 'react-webcam'

const CamBox = styled.div`
max-width: 340px;
margin: 0 auto;
border-radius: 5px;
background: white;
background: linear-gradient(0deg, #f5f5f5, #fff);
text-align: center;
border: 1px solid #7D8F9B;
box-shadow: 0 2px 10px rgba(0,0,0,0.05);

.capture-region{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-bottom: 1px solid #7D8F9B;
  min-height: 260px;
  video{
    border-radius: 5px;
    overflow: hidden;
  }
}

svg{
  width: 324px;
  stroke-width:0;
  margin-bottom: 1px;
  fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;
   .cam-circler{
    fill: #C2DDF8;
   }
   .cam-shaper{
    fill: #7D8F9B;
    }
    .cam-stroker{
     fill: none;
     stroke: #7D8F9B;
     stroke-width:6px;
    }
}
.foot-section{
  padding: 10px;
}

`;


export const WebcamCapture = () => {
  const [taking, setTaking] = React.useState(false)
  const webcamRef: any = React.useRef(null)

  const capture = React.useCallback(() => {
   //eslint-disable-next-line
   const imageSrc = webcamRef.current.getScreenshot()
    setTaking(false)
  }, [webcamRef])
  const start = React.useCallback(() => {
    setTaking(true)
  }, [])
  return (
    <CamBox>
      {taking && (
        <div className="capture-region">
        <Webcam
          audio={false}
          height={240}
          ref={webcamRef}
          screenshotFormat='image/jpeg'
          width={320}
        />
        </div>
      )}
      {!taking ? (
        <Box>
          <div className="capture-region">
          <svg viewBox="0 0 320 240">
    <g transform="matrix(1,0,0,1,-25.6899,28.2794)">
        <g transform="matrix(2.28592,0,0,2.28592,186.338,73.4533)" className="cam-circler">
            <path d="M0,10C-2.761,10 -5,7.762 -5,5C-5,2.238 -2.761,0 0,0C2.761,0 5,2.238 5,5C5,7.762 2.761,10 0,10M0,-2C-3.866,-2 -7,1.134 -7,5C-7,8.866 -3.866,12 0,12C3.866,12 7,8.866 7,5C7,1.134 3.866,-2 0,-2"/>
        </g>
        <g transform="matrix(2.28592,0,0,2.28592,224.888,49.423)" className="cam-shaper">
            <path d="M0,37.566C-1.121,38.698 -2.839,38.847 -4.124,38.025L-5.021,37.18L-5.022,37.181C-7.938,33.818 -12.108,31.488 -16.854,31.488C-21.726,31.488 -26.414,33.832 -29.322,37.337L-29.927,37.948C-31.266,38.878 -33.072,38.714 -34.272,37.503C-35.58,36.182 -35.339,34.13 -34.104,32.76L-34.084,32.78C-31.437,30.027 -28.083,27.822 -24.392,26.595L-24.383,26.612C-22.141,27.807 -19.583,28.49 -16.864,28.49C-14.248,28.49 -11.785,27.849 -9.604,26.736C-5.989,27.959 -2.755,30.024 -0.146,32.719L-0.132,32.706L-0.129,32.71L-0.146,32.719L-0.153,32.726C1.122,34.039 1.29,36.262 0,37.566M-30.864,12.501C-30.864,4.774 -24.596,-1.489 -16.864,-1.489C-9.132,-1.489 -2.864,4.774 -2.864,12.501C-2.864,20.227 -9.132,26.491 -16.864,26.491C-24.596,26.491 -30.864,20.227 -30.864,12.501M1.605,31.746L1.603,31.806L1.025,31.048L1.015,31.059C-1.389,28.643 -4.273,26.729 -7.463,25.419C-3.47,22.511 -0.864,17.816 -0.864,12.501C-0.864,3.67 -8.028,-3.488 -16.864,-3.488C-25.701,-3.488 -32.864,3.67 -32.864,12.501C-32.864,17.718 -30.351,22.336 -26.481,25.254C-26.467,25.249 -26.454,25.241 -26.44,25.235L-26.45,25.254C-29.793,26.6 -32.895,28.704 -35.379,31.253L-35.404,31.227C-37.504,33.376 -37.788,36.777 -35.671,38.916C-33.555,41.054 -30.072,41.001 -27.945,38.878L-27.431,38.209C-24.895,35.32 -20.977,33.486 -16.854,33.486C-12.597,33.486 -9.023,35.43 -6.483,38.482L-5.374,39.468C-3.334,40.943 -0.489,40.773 1.343,38.922C3.26,36.986 3.298,33.83 1.605,31.746" />
        </g>
    </g>
    <g transform="matrix(0.423141,0,0,0.487874,7.26365,8.87533)" className="cam-stroker">
        <path d="M62.255,5.711L6.806,5.711L6.806,59.549"/>
    </g>
    <g transform="matrix(-0.423141,-5.18198e-17,5.97474e-17,-0.487874,312.1,232.134)" className="cam-stroker">
        <path d="M62.255,5.711L6.806,5.711L6.806,59.549"/>
    </g>
</svg>
        </div>
<Spacer space />
<div className="foot-section"><Button onClick={start} iconLeft={<i className="form-camera" />} color="#7D8F9B">Start Camera</Button></div>
        </Box>
      ) : (
        <div className="foot-section"><Button onClick={capture} iconLeft={<i className="form-dot-circled" />}>Capture photo</Button></div>
      )}
    </CamBox>
  )
}
