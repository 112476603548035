import { generateSchema,getSchemaFromApi } from "./api";
function swap(Array, a, b) {
  const newArray = [...Array];
  let temp = newArray[b];
  newArray[b] = newArray[a];
  newArray[a] = temp;
  //   newArray[b].id = a + 1;
  //   newArray[a].id = b + 1;
  return newArray.map((t, index) => {
    return {
      ...t,
      id: index + 1,
    };
  });
}

function minify(schema) {
  const temp = JSON.parse(JSON.stringify(schema));

  temp.layout.sections.forEach((section) => {
    delete section.id;
    section.rows.forEach((row) => {
      delete row.id;
      row.cols.forEach((col) => {
        delete col.id;
        if (!col?.props?.name && col?.props?.label)
          col.props.name = splitNames(col.props.label);
      });
    });
  });

  return temp;
}
function magnify(schema) {
  let temp = JSON.parse(JSON.stringify(schema));
  if(!temp.layout){
    temp = {
      layout: { wizard:true, sections: [] }
    }
  }
  
  temp.layout.sections.forEach((section, index) => {
    section.id = index + 1;
    section.rows.forEach((row, rowIndex) => {
      row.id = rowIndex + 1;
      row.cols.forEach((col, colIndex) => {
        col.id = colIndex + 1;
      });
    });
  });

  return temp;
}
function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

function splitNames(label) {
  const splitted = label.split(" ");
  if (splitted.length <= 1) return splitted[0];
  return splitted.join("_");
}

export { swap, generateSchema, minify, getQueryVariable, magnify,getSchemaFromApi };
