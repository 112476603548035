import React from "react";
import styled, { css } from "styled-components";

import PropTypes from "prop-types";
import { Theme } from "./theme";
// import { Box } from './layout';
import { darken } from "polished";

export const Icon = styled.i<any>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${(props: any) => props.size}px;
    height: ${(props: any) => props.size}px;
    font-size: ${(props: any) => props.size - 4}px;
    ${(props: any) =>
      props.font &&
      css`
        font-size: ${(innerProp: any) => innerProp.font};
      `}
    ${(props: any) =>
      props.color &&
      css`
        color: ${(innerProp: any) => innerProp.color};
      `}

    ${(props: any) =>
      props.boxed &&
      css`
        font-size: ${(innerProp: any) => innerProp.font};
        width: ${(innerProp: any) => innerProp.size}px;
        height: ${(innerProp: any) => innerProp.size}px;
        background: ${(innerProp: any) => innerProp.bg};
        &:hover {
          background: ${(innerProp: any) => innerProp.bgHover};
        }
        ${(innerProp: any) =>
          innerProp.round &&
          css`
            border-radius: 50%;
          `}
        ${(innerProp: any) =>
          innerProp.curve &&
          css`
            border-radius: ${Theme.PrimaryRadius};
          `}
      `}
    transition: all 0.3s ease-out;
    ${(props: any) =>
      props.rotate &&
      css`
        transform: rotateZ(${(innerProp: any) => innerProp.rotate});
      `}
`;

Icon.defaultProps = {
  size: 20,
};
Icon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  font: PropTypes.string,
  round: PropTypes.bool,
  boxed: PropTypes.bool,
  bg: PropTypes.string,
  bgHover: PropTypes.string,
};

export const StringComp = (props: any) => {
  return <>{props.children}</>;
};

export const LabelWidth = styled.div`
  width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const H3 = styled.h3<any>`
  font-size: ${(props: any) => props.size};
  font-weight: lighter;
  font-family: ${Theme.SecondaryFont};
  margin: ${(props: any) => props.margin};
  color: ${(props: any) => props.color};
  letter-spacing: 1px;
`;
H3.defaultProps = {
  size: "22px",
  color: Theme.PrimaryFontColor,
  margin: "0",
};
H3.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  margin: PropTypes.string,
};

export const Title = styled.span`
  font-weight: bold;
  display: block;
`;

export const SubTitle = styled.span`
  font-size: ${Theme.FontSize_s};
  font-family: ${Theme.PrimaryFont};
  font-weight: lighter;
  opacity: 0.6;
  /* display: block; */
`;

export const IconTextWrapper = styled.span<any>`
  display: inline-block;
  padding: 0 10px;
  font-size: ${Theme.FontSize_s};
  color: ${Theme.PrimaryFontColor};
  ${(props: any) =>
    props.color &&
    css`
      color: ${(innerProp: any) => innerProp.color};
    `}
  & i {
    display: inline-block;
    padding-right: 5px;
    ${(props: any) =>
      props.iconColor &&
      css`
        color: ${(innerProp: any) => innerProp.iconColor};
      `}
  }
`;

export const IconText = (props: any) => {
  return (
    <IconTextWrapper {...props}>
      <i className={props.icon} />
      <StringComp>{props.children}</StringComp>
    </IconTextWrapper>
  );
};

export const Text = styled.span<any>`
    ${(props: any) =>
      props.bold &&
      css`
        font-weight: bold;
      `}
    ${(props: any) =>
      props.block &&
      css`
        display: block;
      `}
    ${(props: any) =>
      props.inlineBlock &&
      css`
        display: inline-block;
      `}
    ${(innerProp: any) =>
      innerProp.pad &&
      css`
        padding: ${(nestedProp: any) => nestedProp.pad};
      `}
    ${(innerProp: any) =>
      innerProp.margin &&
      css`
        margin: ${(nestedProp: any) => nestedProp.margin};
      `}
    color: ${(props: any) => props.color};
    font-size: ${(props: any) => props.size};
    & a{
        font-weight: 600;
        color: ${Theme.PrimaryColor};
        transition: all 0.2s ease-out;
        text-decoration: none;
        &:hover{
            color: ${darken(0.1, Theme.PrimaryColor)};
        }
    }
`;

Text.defaultProps = {
  size: Theme.PrimaryFontSize,
};
Text.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  bold: PropTypes.bool,
  block: PropTypes.bool,
  inlineBlock: PropTypes.bool,
  pad: PropTypes.string,
  margin: PropTypes.string,
};

const LabellerWrapper = styled.div`
  display: inline-block;
  align-items: flex-start;
  position: relative;
  padding-left: 20px;
  font-size: ${(props: any) => props.size}px;
  ${(props: any) =>
    props.icon &&
    css`
      & i {
        position: absolute;
        top: 0;
        left: 0;
      }
    `}
  ${Text} {
    & span {
      font-size: 10px;
    }
  }
`;

export const Labeller = (props: any) => {
  return (
    <LabellerWrapper {...props}>
      {props.icon && (
        <Icon className={props.icon} color={props.color} size={14} />
      )}
      <Text bold={true} block={true} size="10px" color={props.color}>
        {props.label}
      </Text>
      <Box height="5px" />
      <Text block={true} bold={props.bold} size={`${props.size}px`}>
        {props.value}
      </Text>
    </LabellerWrapper>
  );
};

Labeller.defaultProps = {
  color: Theme.PrimaryOrange,
  size: 15,
};
Labeller.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.any,
  icon: PropTypes.string,
  bold: PropTypes.bool,
  size: PropTypes.number,
};

export const Box = styled.div<any>`
  display: ${(props: any) => props.display};
  position: ${(props: any) => props.position};
  ${(props: any) =>
    props.width &&
    css`
      width: ${(innerProp: any) => innerProp.width};
    `}
  ${(props: any) =>
    props.height &&
    css`
      height: ${(innerProp: any) => innerProp.height};
    `}
  ${(props: any) =>
    props.minWidth &&
    css`
      min-width: ${(innerProp: any) => innerProp.minWidth};
    `}
  ${(props: any) =>
    props.maxWidth &&
    css`
      max-width: ${(innerProp: any) => innerProp.maxWidth};
    `}
  ${(props: any) =>
    props.minHeight &&
    css`
      min-height: ${(innerProp: any) => innerProp.minHeight};
    `}
  ${(props: any) =>
    props.maxHeight &&
    css`
      max-height: ${(innerProp: any) => innerProp.maxHeight};
    `}
  ${(props: any) =>
    props.background &&
    css`
      background: ${(innerProp: any) => innerProp.background};
    `}
  ${(props: any) =>
    props.color &&
    css`
      color: ${(innerProp: any) => innerProp.color};
    `}
  ${(props: any) =>
    props.align &&
    css`
      text-align: ${(innerProp: any) => innerProp.align};
    `}
  ${(props: any) =>
    props.fullscreen &&
    css`
      min-width: 100vh;
      min-height: 100vh;
    `}
  ${(props: any) =>
    props.verticalAlign &&
    css`
      display: flex;
      align-items: center;
    `}
  ${(props: any) =>
    props.horizontalAlign &&
    css`
      display: flex;
      justify-content: center;
    `}
  ${(props: any) =>
    props.centered &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
  ${(props: any) =>
    props.pad &&
    css`
      padding: ${(innerProp: any) => innerProp.pad};
    `}
  ${(props: any) =>
    props.pad &&
    css`
      padding: ${(innerProp: any) => innerProp.pad};
    `}
  ${(props: any) =>
    props.bordered &&
    css`
      border: 1px ${(innerProp: any) => innerProp.borderStyle}
        ${(innerProp: any) => innerProp.border};
    `}
  ${(props: any) =>
    props.sideBordered &&
    css`
      border-left: 1px ${(innerProp: any) => innerProp.borderStyle}
        ${(innerProp: any) => innerProp.border};
      border-right: 1px ${(innerProp: any) => innerProp.borderStyle}
        ${(innerProp: any) => innerProp.border};
    `}
  ${(props: any) =>
    props.bottomBordered &&
    css`
      border-bottom: 1px ${(innerProp: any) => innerProp.borderStyle}
        ${(innerProp: any) => innerProp.border};
    `}
  ${(props: any) =>
    props.leftBordered &&
    css`
      border-left: 1px ${(innerProp: any) => innerProp.borderStyle}
        ${(innerProp: any) => innerProp.border};
    `}
  ${(props: any) =>
    props.rightBordered &&
    css`
      border-right: 1px ${(innerProp: any) => innerProp.borderStyle}
        ${(innerProp: any) => innerProp.border};
    `}
  ${(props: any) =>
    props.margin &&
    css`
      margin: ${(innerProp: any) => innerProp.margin};
    `}
  ${(props: any) =>
    props.verticalPad &&
    css`
      padding-top: ${(innerProp: any) => innerProp.verticalPad};
      padding-top: ${(innerProp: any) => innerProp.verticalPad};
    `}
  ${(props: any) =>
    props.horizontalPad &&
    css`
      padding-left: ${(innerProp: any) => innerProp.horizontalPad};
      padding-right: ${(innerProp: any) => innerProp.horizontalPad};
    `}
  ${(props: any) =>
    props.elevate &&
    css`
      ${Theme.Elevate};
      ${(innerProp: any) =>
        innerProp.elevate === "high" &&
        css`
          ${Theme.Elevate_2}
        `};
      ${(innerProp: any) =>
        innerProp.elevate === "higher" &&
        css`
          ${Theme.Elevate_3}
        `};
    `}
  ${(props: any) =>
    props.radius &&
    css`
      border-radius: ${Theme.PrimaryRadius};
      ${(innerProp: any) =>
        innerProp.radius === "high" &&
        css`
          border-radius: ${Theme.SecondaryRadius};
        `}
    `}
  ${(props: any) =>
    props.hidden &&
    css`
      overflow: hidden;
    `}
  ${(props: any) =>
    props.transition &&
    css`
      transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    `}
  ${(props: any) =>
    props.tag &&
    css`
      position: relative;
      &::after {
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        left: -4px;
        width: 4px;
        background: ${(innerProp: any) => innerProp.tag};
      }
    `}
`;

Box.defaultProps = {
  display: "block",
  position: "none",
  borderStyle: "solid",
  border: Theme.PrimaryBorderColor,
};

Box.propTypes = {
  display: PropTypes.string,
  position: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,

  background: PropTypes.string,
  fullscreen: PropTypes.bool,
  pad: PropTypes.string,
  margin: PropTypes.string,
  verticalPad: PropTypes.string,
  horizontalPad: PropTypes.string,
  verticalAlign: PropTypes.bool,
  color: PropTypes.string,
  align: PropTypes.string,

  bordered: PropTypes.bool,
  bottomBordered: PropTypes.bool,
  sideBordered: PropTypes.bool,
  leftBordered: PropTypes.bool,
  rightBordered: PropTypes.bool,
  border: PropTypes.string,
  borderStyle: PropTypes.string,
  radius: PropTypes.any,
  elevate: PropTypes.string,
  hidden: PropTypes.bool,
  transition: PropTypes.bool,
};
