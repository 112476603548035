const refereeDefaultFields = {
  id: 1,
  cols: [
    {
      id: 1,
      type: "plain",
      props: {
        label: "full name",
        radio: [],
        required: true,
        placeholder: "Full Name",
      },
      rules: {},
      element: "input",
      elementId: "15",
      fieldId: "referee_full_name",
    },
    {
      id: 2,
      type: "email",
      props: {
        label: "email address",
        radio: [],
        required: true,
        placeholder: "Email",
      },
      rules: {},
      element: "input",
      elementId: "17",
      fieldId: "referee_email",
    },
    {
      id: 3,
      type: "tel",
      props: {
        label: "Phone",
        radio: [],
        required: false,
        placeholder: "0803333333",
      },
      rules: {},
      element: "input",
      elementId: "29",
      fieldId: "referee_phone",
    },
  ],
  default: true,
};

export default refereeDefaultFields;