import React, { useState, useEffect } from "react";
import styled from "styled-components";
// import { IActiveField, ISchema } from "./FormBuilder/FormBuilder";
import { Select, Datepicker, Input, Text, Switch, TextArea, Checkbox, Grid, Button as FlexiButton, Modal, ModalBody, ModalClose, Spacer, ModalFooter } from "@flexisaf/flexibull2";
import { InputLabel, Theme } from "./";
import { Button } from "./style";
import { Box } from "./typo";


const AlternateLabels = {
  23: 'Attachment',
  27: 'Photo'
}
const PropRows = styled.div`
  margin: 10px 0px;
  display: flex;
`;
const HideElementRow = styled.div`
  margin: 10px 0px;
  & .caution {
    font-style: italic;
    font-weight: 400;
  }
`;
export const OptionRows = styled.div`
  margin: 10px 0px;
  display: flex;
  padding: 10px;
  background-color: #bdbdbd3d;
  flex-direction: column;
  justify-content: space-evenly;
`;
export const MapGrid = styled(Grid)`
  text-align: left;
  margin: 15px auto;

  .flexi-select {
    width: 100%;
  }
`;
interface IElementProperties {
  activeField: any | {};
  changeProps: (props, row, column, section) => void;
  changeElementSkip: (skip, section) => void;
  schema: any;
}
export const ElementProperties = ({
  activeField,
  changeProps,
  schema,
  changeElementSkip
}: IElementProperties) => {
  const { elementId, row, col, section, defaultSection }: any = activeField;


  const [sectionMappingModal, setSectionMappingModal] = useState(false);
  const [label, setLabel] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [guide, setGuide] = useState("");
  const [trueDisplay, setTrueDisplay] = useState("");
  const [falseDisplay, setFalseDisplay] = useState("");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [useCommaField, setUseCommaField] = useState(false);
  const [commaSelectOptions, setCommaSelectOptions] = useState('');
  const [checked, toggleChecked] = useState<boolean>(false);
  const [hidden, setHidden] = useState<boolean>(false);
  const [selectOptions, toggleSelectOptions] = useState<{ label: string, value?: string }[]>([]);
  const [skipMapping, setSkipMapping] = useState<[string | null, string | null][]>([]);
  const [radioOptions, toggleRadioOptions] = useState<{ label: string, value?: string }[]>([]);
  const [alternateLabel, setAltabel] = useState("");

  const getSelectedOptions = () => {
    const opts = commaSelectOptions.split(',')?.map(o => ({ label: o.trim(), value: o.trim() }));
    toggleSelectOptions(opts);
    let props =
      schema?.layout?.sections?.[section]?.rows?.[row].cols?.[col].props;
    let newProps = { ...props, options: opts };
    changeProps(newProps, row, col, section);
    console.log(newProps, row, col, section)
  }

  const getSections = () => {
    return schema?.layout?.sections?.filter((_, ind) => (ind > section))?.map(sec => ({ label: sec.title, value: sec.id }));
  }

  const getMappingOptions = (i) => {
    const mappedOptions = skipMapping?.map(a => a[0]).filter((a, ai) => a && ai !== i);
    return selectOptions.filter(a => !mappedOptions.includes(`${a.value}`));
  }

  const handleCommaFieldChange = (v) => {
    if (v) {
      setCommaSelectOptions(selectOptions?.map(o => o.label).join(','))
    } else {
      getSelectedOptions();
    }
  }

  useEffect(() => {
    const elem = schema?.layout?.sections?.[section]?.rows?.[row]?.cols?.[col]?.props;

    setLabel(elem?.label || "");
    setPlaceholder(elem?.placeholder || "");
    setGuide(elem?.guide || "");
    toggleChecked(elem?.required || false);
    setHidden(elem?.hide || false);
    toggleSelectOptions(elem?.options || []);
    setCommaSelectOptions((elem?.options || [])?.map(o => o.label).join(','));
    toggleRadioOptions(elem?.radio || []);
    setEndDate(elem?.endDate || '');
    setStartDate(elem?.startDate || '');
    setAltabel(elem?.alternateLabel || '');
  }, [col, row, schema, section]);

  const handleSaveSkipMapping = () => {
    let skip = { ...(schema?.layout?.sections?.[section]?.skip || {}) };
    const fieldId = schema?.layout?.sections?.[section]?.rows?.[row].cols?.[col]?.fieldId;
    if (!skip[fieldId]) {
      skip[fieldId] = {};
    }
    skipMapping.forEach(a => {
      if (a[0]) {
        skip[fieldId][a[0]] = a[1]
      }
    })
    changeElementSkip(skip, section)
    setSectionMappingModal(false)
  };

  const handleLabelChange = (value: any) => {
    setLabel(value);
    let props =
      schema?.layout?.sections?.[section]?.rows?.[row]?.cols?.[col]?.props;
    let newProps = { ...props, label: value };
    changeProps(newProps, row, col, section);
  };
  const handlePlaceholderChange = (value: any) => {
    setPlaceholder(value);
    let props =
      schema?.layout?.sections?.[section]?.rows?.[row]?.cols?.[col]?.props;
    let newProps = { ...props, placeholder: value };
    changeProps(newProps, row, col, section);
  };
  const handleGuideChange = (value: any) => {
    setGuide(value);
    let props =
      schema?.layout?.sections?.[section]?.rows?.[row]?.cols?.[col]?.props;
    let newProps = { ...props, guide: value };
    changeProps(newProps, row, col, section);
  };
  const handleRequiredChange = (e: any) => {
    toggleChecked(e);
    let props =
      schema?.layout?.sections?.[section]?.rows?.[row]?.cols?.[col]?.props;
    let newProps = { ...props, required: e, hide: e ? !e : hidden };
    changeProps(newProps, row, col, section);
  };
  const handleHiddenChange = (e: any) => {
    setHidden(e);
    let props =
      schema?.layout?.sections?.[section]?.rows?.[row]?.cols?.[col]?.props;
    let newProps = { ...props, hide: e, required: e ? !e : checked };
    changeProps(newProps, row, col, section);
  };
  const handleOptionsChange = (e: any, index) => {
    let newOptions = [...selectOptions];
    newOptions[index] = {
      value: e.target.value,
      label: e.target.value,
    };
    toggleSelectOptions(newOptions);
    let props =
      schema?.layout?.sections?.[section]?.rows?.[row].cols?.[col].props;
    let newProps = { ...props, options: newOptions };
    changeProps(newProps, row, col, section);
  };

  const handleOptionDelete = (index) => {
    let newOptions = selectOptions.filter((_, i) => i !== index);
    toggleSelectOptions(newOptions);
    let props =
      schema?.layout?.sections?.[section]?.rows?.[row].cols?.[col].props;
    let newProps = { ...props, options: newOptions };
    changeProps(newProps, row, col, section);
  };
  
  const handleAltLabelChange = (value: any) => {
    setAltabel(value);
    let props =
      schema?.layout?.sections?.[section]?.rows?.[row].cols?.[col].props;
    let newProps = { ...props, alternateLabel: value };
    changeProps(newProps, row, col, section);
  };

  const handleTrueDisplayChange = (value: any) => {
    setTrueDisplay(value);
    let props =
      schema?.layout?.sections?.[section]?.rows?.[row].cols?.[col].props;
    let newProps = { ...props, trueDisplay: value };
    changeProps(newProps, row, col, section);
  };

  const handleFalseDisplayChange = (value: any) => {
    setFalseDisplay(value);
    let props =
      schema?.layout?.sections?.[section]?.rows?.[row].cols?.[col].props;
    let newProps = { ...props, falseDisplay: value };
    changeProps(newProps, row, col, section);
  };

  const handleRadioChange = (e: any, index) => {
    let newOptions = JSON.parse(JSON.stringify(radioOptions));
    newOptions[index] = {
      value: e.target.value,
      label: e.target.value,
    };

    let props =
      schema?.layout?.sections?.[section]?.rows?.[row].cols?.[col].props;
    let newProps = JSON.parse(JSON.stringify({ ...props, radio: newOptions }));
    changeProps(newProps, row, col, section);
  };

  const handleRadioDelete = (index) => {
    let newOptions = JSON.parse(JSON.stringify(radioOptions)).filter((_, i) => i !== index);

    let props =
      schema?.layout?.sections?.[section]?.rows?.[row].cols?.[col].props;
    let newProps = JSON.parse(JSON.stringify({ ...props, radio: newOptions }));
    changeProps(newProps, row, col, section);
  };

  const handleEndDateChange = (val) => {
    setEndDate(val);
    let props =
      schema?.layout?.sections?.[section]?.rows?.[row].cols?.[col].props;
    let newProps = { ...props, endDate: val };
    changeProps(newProps, row, col, section);
  };

  const handleStartDateChange = (val) => {
    console.log(val)
    setStartDate(val);
    let props =
      schema?.layout?.sections?.[section]?.rows?.[row].cols?.[col].props;
    let newProps = { ...props, startDate: val };
    changeProps(newProps, row, col, section);
  };
  return (
    <div>
      <Input
        name="name"
        value={label}
        onChange={(e: any) => handleLabelChange(e.target.value)}
        label="Label"
        type="text"
        spaceTop
        spaceBottom="30px"
        required
        height="36px"
        block
      />
      <Input
        name="name"
        value={placeholder}
        onChange={(e: any) => handlePlaceholderChange(e.target.value)}
        label="Placeholder"
        type="text"
        spaceTop
        spaceBottom="30px"
        height="36px"
        block
      />
      <TextArea
        label="Input Guide"
        placeholder="Input Guide"
        spaceTop
        type="text"
        spaceBottom="30px"
        height="36px"
        onChange={(e: any) => handleGuideChange(e.target.value)}
        value={guide}
        block
      />
      {/* <Input
                    type="text"
                    label="Label"
                    spaceTop
                    spaceBottom="30px"
                    required
                    height="36px"
                    block
                  /> */}
      {/* <PropRows>
        <Input
          value={label}
          label="label"
          onChange={(e: any) => handleLabelChange(e.target.value)}
        />
      </PropRows>
      <PropRows>
        <Input value="" label="field name" />
      </PropRows>
      <PropRows>
        <Input value="" label="helper text" />
      </PropRows> */}
      {["23", "27"].includes(elementId) && (
        <Input
          name="alternateLabel"
          value={alternateLabel ? alternateLabel : AlternateLabels[elementId]}
          onChange={(e: any) => handleAltLabelChange(e.target.value)}
          label="Alternate Label"
          type="text"
          spaceTop
          spaceBottom="30px"
          required
          height="36px"
          block
        />)}
      {elementId === "18" && (
        <div>
          <Datepicker
            onChange={handleStartDateChange}
            format="dd MMM yyyy"
            block
            value={startDate}
            label="Lower Bound"
            maxDate={endDate || null}
          />

          <div style={{ height: '40px' }} />
          <Datepicker
            onChange={handleEndDateChange}
            format="dd MMM yyyy"
            block
            value={endDate}
            minDate={startDate || null}
            label="Upper Bound"
          />
        </div>
      )}
      {elementId === "20" && (
        <div>
          <Input
            name="trueDisplay"
            value={trueDisplay}
            onChange={(e: any) => handleTrueDisplayChange(e.target.value)}
            label="True Display"
            type="text"
            spaceTop
            spaceBottom="30px"
            height="36px"
            block
          />
          <Input
            name="falseDisplay"
            value={falseDisplay}
            onChange={(e: any) => handleFalseDisplayChange(e.target.value)}
            label="False Display"
            type="text"
            spaceTop
            spaceBottom="30px"
            height="36px"
            block
          />
        </div>
      )}
      {elementId === "19" && (
        <>
          <FlexiButton pale spaceRight onClick={() => {
            let skip = { ...(schema?.layout?.sections?.[section]?.skip || {}) };
            const fieldId = schema?.layout?.sections?.[section]?.rows?.[row].cols?.[col]?.fieldId;
            if (!skip[fieldId]) {
              setSkipMapping([[null, null]])
              setSectionMappingModal(true);
              return;
            }
            setSkipMapping(Object.keys(skip[fieldId]).sort()?.map(se => ([se, skip[fieldId][se]])))
            setSectionMappingModal(true);
          }}>
            Set sections based on answers
          </FlexiButton>
          <Checkbox label="Use Comma field" checked={useCommaField} onChange={() => {
            setUseCommaField(!useCommaField)
            handleCommaFieldChange(!useCommaField)
          }
          } />
          <OptionRows>
            <InputLabel>Options</InputLabel>
            {
              useCommaField ? (
                <Box pad="0 0 10px">
                  <TextArea
                    value={commaSelectOptions}
                    placeholder={'options'}
                    onChange={e => setCommaSelectOptions(e.target.value)}
                    onBlur={getSelectedOptions}
                    style={{ width: '100%' }}
                  />
                </Box>
              ) : (
                <>
                  {
                    selectOptions?.map((elem, index) => (
                      <Grid default="4fr 1fr">
                        <Input
                          key={index}
                          value={elem?.label || ""}
                          placeholder={`option ${index + 1}`}
                          data-index={`${index + 1}`}
                          onChange={e => handleOptionsChange(e, index)}
                        />
                        <FlexiButton
                          onClick={() => handleOptionDelete(index)}
                          pale
                        >
                          <i className="form-trash-empty" />
                        </FlexiButton>
                      </Grid>
                    ))
                  }
                  <Button onClick={() => toggleSelectOptions([...selectOptions, { label: '' }])}>Add Options</Button>
                </>
              )
            }
          </OptionRows>
          <Modal outerClick open={sectionMappingModal}>
            <ModalBody width="800px" style={{ borderRadius: 0 }}>
              <ModalClose onClick={() => setSectionMappingModal(false)}>&times;</ModalClose>
              <Box pad="30px">
                <h1>Set sections based on answers</h1>
                <Text>Set what happens when a user selects an answer options</Text>
                <Spacer space="30px" />
                <Box>
                  {
                    skipMapping?.map((m, mi) => (
                      <Box pad="5px 0px">
                        <MapGrid key={mi} default="1fr 130px 1fr 40px" lr="1fr 130px 1fr" md="1fr 100px 1fr">
                          <Select
                            value={{ label: m[0], value: m[0] }}
                            label="If you select"
                            options={getMappingOptions(mi)}
                            onChange={(a) => {
                              setSkipMapping(c => {
                                const newC = [...c];
                                newC[mi][0] = a.value;
                                return newC;
                              })
                            }}
                          />
                          <div />
                          <Select
                            value={getSections().find(a => a.value === m[1])}
                            label="Go to"
                            options={getSections()}
                            onChange={(a) => {
                              setSkipMapping(c => {
                                const newC = [...c];
                                newC[mi][1] = a.value;
                                return newC;
                              })
                            }}
                          />
                          <FlexiButton
                            onClick={() => setSkipMapping(c => c.filter((_, ci) => ci !== mi))}
                            pale
                          >
                            <i className="form-trash-empty" />
                          </FlexiButton>
                        </MapGrid>
                      </Box>
                    ))
                  }
                  <FlexiButton
                    onClick={() => {
                      setSkipMapping(c => ([...c, [null, null]]))
                    }}
                    pale
                    disabled={selectOptions.length === skipMapping.length}
                  >
                    Add Row
                  </FlexiButton>
                  <Spacer space="20px" />
                  <ModalFooter>
                    <Grid default="1fr 1fr">
                      <Box pad="10px" align="left">
                        <FlexiButton
                          height={32}
                          onClick={() => setSectionMappingModal(false)}
                          pale
                        >
                          Close
                        </FlexiButton>
                      </Box>
                      <Box pad="10px" align="right">
                        <FlexiButton
                          height={32}
                          onClick={handleSaveSkipMapping}
                        >
                          Save
                        </FlexiButton>
                      </Box>

                    </Grid>
                  </ModalFooter>
                </Box>
              </Box>
            </ModalBody>
          </Modal>
        </>
      )}
      {elementId === "22" && (
        <OptionRows>
          <InputLabel>Options</InputLabel>
          {
            radioOptions?.map((elem, index) => (
              <Grid default="4fr 1fr">
                <Input
                  key={index}
                  value={elem?.label || ""}
                  placeholder={`option ${index + 1}`}
                  data-index={`${index + 1}`}
                  onChange={e => handleRadioChange(e, index)}
                />
                <FlexiButton
                  onClick={() => handleRadioDelete(index)}
                  pale
                >
                  <i className="form-trash-empty" />
                </FlexiButton>
              </Grid>
            ))
          }
          <Button onClick={() => toggleRadioOptions([...radioOptions, { label: '', value: '' }])}>Add Option</Button>
        </OptionRows>
      )}
      {(defaultSection && elementId === "27") && <HideElementRow>
        <Text className="caution" color={Theme.PrimaryRed}>
            NB: Hidden items will not be visible to users when filling the form & 
            a hidden field can not be required vice versa
        </Text>
        <Switch
          label="Hide Field"
          spaceLeft="-10px"
          checked={hidden}
          onChange={(e) => handleHiddenChange(e.target.checked)}
        />
      </HideElementRow>}
      <PropRows>
        <Switch
          label="Required"
          spaceLeft="-10px"
          checked={checked}
          onChange={(e) => handleRequiredChange(e.target.checked)}
        />
      </PropRows>
    </div>
  );
};
