/*eslint eqeqeq: "off"*/
export const nigeria = {
    label: ['State', 'LGA'],
    data: [
        {
            name: 'Abia',
            level: 1,
            options: [
                {name: 'lga 1', level: 2},
                {name: 'lga 2', level: 2},
                {name: 'lga 3', level: 2},
                {name: 'lga 4', level: 2},
                {name: 'lga 5', level: 2},
            ]
        },
        {
            name: 'Adamawa',
            level: 1,
            options: [
                {name: 'adamawa 1', level: 2},
                {name: 'adamawa 2', level: 2},
                {name: 'adamawa 3', level: 2},
                {name: 'adamawa 4', level: 2},
                {name: 'adamawa 5', level: 2},
            ]
        },
        {
            name: 'Bayelsa',
            level: 1,
            options: [
                {name: 'bayelsa 1', level: 2},
                {name: 'bayelsa 2', level: 2},
                {name: 'bayelsa 3', level: 2},
                {name: 'bayelsa 4', level: 2},
                {name: 'bayelsa 5', level: 2},
            ]
        }
    ]
}

export const countries = {
    label: ['countries'],
    data: [
        {
            name: 'Nigeria',
            level: 1
        },
        {
            name: 'Canada',
            level: 1,
            
        },
        {
            name: 'Jamaica',
            level: 1,
            
        },
        {
            name: 'India',
            level: 1
        },
        {
            name: 'United Kingdom',
            level: 1,
            
        },
        {
            name: 'Bolivia',
            level: 1,
            
        }
    ]
}

export const faculties = {
    label: ['Faculty', 'Department', 'Course'],
    data: [
        {
        name: "Faculty of Engineering",
        level: 1,
        options: [
        {
        name: "Electrical Engineering",
        level: 2,
        options: [
        {
        name: "ECSE 221",
        level: 3
        },
        {
        name: "ECSE 318",
        level: 3
        },
        {
        name: "ECSE 124",
        level: 3
        },
        {
        name: "ECSE 390",
        level: 3
        }
        ]
        },
        {
        name: "Mechanical Engineering",
        level: 2,
        options: [
        {
        name: "MECH 221",
        level: 3
        },
        {
        name: "MECH 318",
        level: 3
        },
        {
        name: "MECH 124",
        level: 3
        },
        {
        name: "MECH 390",
        level: 3
        }
        ]
        },
        {
        name: "Civil Engineering",
        level: 2,
        options: [
        {
        name: "CIV 221",
        level: 3
        },
        {
        name: "CIV 318",
        level: 3
        },
        {
        name: "CIV 124",
        level: 3
        },
        {
        name: "CIV 390",
        level: 3
        }
        ]
        },
        {
        name: "Software Engineering",
        level: 2,
        options: [
        {
        name: "SOF 221",
        level: 3
        },
        {
        name: "SOF 318",
        level: 3
        },
        {
        name: "SOF 124",
        level: 3
        },
        {
        name: "SOF 390",
        level: 3
        }
        ]
        }
        ]
        },
        {
        name: "Faculty of Arts",
        level: 1,
        options: [
        {
        name: "Law",
        level: 2,
        options: [
        {
        name: "llb 221",
        level: 3
        },
        {
        name: "llb 318",
        level: 3
        },
        {
        name: "llb 124",
        level: 3
        },
        {
        name: "llb 390",
        level: 3
        }
        ]
        },
        {
        name: "Economics",
        level: 2,
        options: [
        {
        name: "econ 221",
        level: 3
        },
        {
        name: "econ 318",
        level: 3
        },
        {
        name: "econ 124",
        level: 3
        },
        {
        name: "econ 390",
        level: 3
        }
        ]
        }
        ]
        }
        ]
}

export const getLastLevel = (arr) => {
    if(!arr?.[0].options){
        return 1
    }
    return arr?.[0]?.options?.[0]?.options?.length > 0 ? 3 : 2
}

export const getLevel = (arr,val,level) => {
    let ans: any = []
    switch(level){
        case 1: 
        ans = arr.map((item) => {
            return {
                label: item.name,
                value: item.name
            }
        })
        break;
        case 2: 
        ans = arr.filter((item) => {
            return item.name == val[level - 2]
        })[0]?.options.map((arr) => {
            return {
                label: arr.name,
                value: arr.name
            }
        })
        break;
        case 3: 
        ans = arr.filter((item) => {
            return item.name == val[level - 3]
        })[0]?.options.filter((itemTwo) => {
            return itemTwo.name == val[level - 2]
        })[0]?.options.map((arr) => {
            return {
                label: arr.name,
                value: arr.name
            }
        })
        break;

        default:
            ans = arr
    }

    return ans

}