import React, { useState } from "react";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Input, Modal, ModalBody, ModalClose, Box, Button, Spacer, Text, Loader } from "@flexisaf/flexibull2";
import { Theme } from "./theme";




export const CustomDataSource = () => {
    const [pulling, setPulling] = useState(false);
    const [preview, setPreview] = useState(false);
    const codeString= `{"data": [
    {
        "organisations": [
            { "name": "Limanita"},
            { "name": "Labelle"},
            { "name": "Jeleosinmi"},
        ]
    }
]
}`;
    return <Box pad="20px 0">
        <Input
        label="Data Source URL"
        type="text"
        spaceTop
        spaceBottom="10px"
        required
        height="36px"
        block
      />
      <Button progress={pulling} iconRight={<i className="form-arrows-ccw" />} onClick={()=> setPulling(!pulling)}>Pull Data &amp; Validate</Button>
      <Spacer space="15px" />
      <Text color={Theme.PrimaryColor} style={{cursor: "pointer"}} onClick={()=> setPreview(true)}><i className="form-map-signs"/> View sample data structure</Text>
      <Spacer space="15px" />
      <Text bold block>Data Preview</Text>
      <Spacer space="10px" />
        {pulling ? <Loader size={40} color={Theme.PrimaryColor} />
        :<SyntaxHighlighter language="javascript" style={docco} wrapLines customStyle={{ fontSize: "9px"}}>
            {codeString}
        </SyntaxHighlighter>
        }
        <Modal open={preview} center={false}>
            <ModalBody width="400px" bgColor="rgb(248, 248, 255)">
            <ModalClose onClick={() => setPreview(false)}>&times;</ModalClose>
                <Box pad="30px">
                    <Text bold block size="16px">Sample Data Structure</Text>
                    <Spacer space="15px" />
                    <SyntaxHighlighter language="javascript" style={docco} wrapLines>
                        {codeString}
                    </SyntaxHighlighter>
                </Box>
            </ModalBody>
        </Modal>
    </Box>
};
