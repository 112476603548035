/*eslint eqeqeq: "off"*/
import React, { useState, useCallback, useEffect } from "react";
import { Box, EmptyState, Input, Layout, Button, Text, Spacer, Tabs, Select, Loader, Notify, RadioButton, Modal, ModalBody, ModalFooter } from "@flexisaf/flexibull2";
import { swap, getSchemaFromApi, getQueryVariable, magnify } from "./util";
import { Preview, Instruction } from "./Preview"
import { animateScroll as scroll } from 'react-scroll'
import { getLevel, getLastLevel } from './data/compound'
import "./components/fonts/fontello/css/flexiform.css";
import "./components/fonts/fontello/css/animation.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  GridLayout,
  LeftSide,
  RightSide,
  TopSide,
  MainPanel,
  DragElement,
} from "./components/layout";
import { ElementProperties } from "./components/ElementProperties";
import request from './util/request'
import urls from './util/constants'

import { SectionElement, Fader, HiddenInput } from "./components/sections";
import { SectionElement2 } from "./components/sections2";
import elements from "./data/index2";
import refreeDefaultFields from "./data/referee";
import { CustomDataSource } from "./components/CustomDataSource";

const indices = elements.map((el) => el.index);
const keyedElements = elements.reduce((acc, cur) => {
  return { ...acc, [cur.index]: cur }
}, {})
const PrimaryTheme = "#5279FF";

const Theme = {
  PrimaryColor: PrimaryTheme,
  PrimaryGrey: "#62707B",
  PrimaryFontSize: "11px",
  PrimaryFontColor: "#64648b",
  PrimaryRed: "#ea2a8a",
};

const options = [
  { value: "nigeria", label: "Nigeria States" },
  { value: "nigeria-states-lga", label: "Nigeria States and LGA" },
  { value: "countries", label: "Countries" },
  { value: "subjects", label: "Subjects" },
  { value: "custom", label: "Custom Data Source" }
]

const App = (props) => {
  // eslint-disable-next-line
  let schemaFromApi
  const [sectionContext, toggleSectionContext] = useState(true);
  const [error, setError] = useState<null | { message: string }>(null);
  const [loading, setLoading] = useState(false)
  const [preview, togglePreview] = useState(false)
  const [instruction, setInstruction] = useState(false)
  const [refereeModal, setRefereeModal] = useState<any>({
    open: false,
    refereeValue: '',
    sec: ''
  });
  const id = getQueryVariable("id");
  const [customeData, setCustomData] = useState(false);
  const [activeField, setActiveField] = useState<any>({});
  const [activeSection, setActiveSection] = useState<any>({});
  const [sections, setSections] = useState(0);
  const [vals, setVals] = useState<any>([]);
  const [Schema, setSchema] = useState<any>({ name: '', description: '', id: '', layout: { wizard: true, sections: [] } });

  const getToken = async () => {
    try {
      const code = getQueryVariable("code") || '';
      const product = getQueryVariable("product") || '';
      const organization = getQueryVariable("organization") || '';

      if (localStorage.getItem("access_token") && localStorage.getItem("code") === code) {
        return Promise.resolve()
      }
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("user");
      localStorage.removeItem("code");
      const url = `${urls?.UMS_BASE_URL}/auth/products/${product}/organisations/${organization}/transfer`
      const res = await request({ method: 'post', url, data: { code } });

      localStorage.setItem("access_token", res.raw.access_token);
      localStorage.setItem("refresh_token", res.raw.refresh_token);
      localStorage.setItem("user", JSON.stringify(res.raw.user));
      localStorage.setItem("code", code);
      return Promise.resolve()
    } catch (error) {
      console.log(error);
    }
  }

  const getSchema = () => {
    // eslint-disable-next-line
    getSchemaFromApi(id).then((res) => {
      let NewScheme = JSON.parse(JSON.stringify(Schema));

      NewScheme = { ...res.raw.schema }
      NewScheme.name = res.raw.name
      NewScheme.description = res.raw.description
      NewScheme.id = res.raw.id
      if (!NewScheme.layout) {
        NewScheme.layout =
          { wizard: false, sections: [] }
      }
      setSchema(magnify(NewScheme))
    }).catch(() => {
      setError({ message: 'Failed to fetch data!!' });
      return
    }).finally(() => {
      setLoading(false);
    })
  }

  useEffect(() => {
    setLoading(true)
    getToken()
      .then(() => getSchema())
    // eslint-disable-next-line
  }, [])

  const addSection = () => {
    let NewScheme = JSON.parse(JSON.stringify(Schema));
    NewScheme.layout.sections.push({
      id: NewScheme.layout.sections.length > 0 ? NewScheme.layout.sections.length + 1 : sections + 1,
      rows: [{ id: 1, cols: [{ id: 1 }] }],
    });
    setSections(sections + 1);
    setSchema(NewScheme);
    scroll.scrollToBottom()
  };
  const renderElementSpace = (num: number): any => {
    let elem: { id: number }[] = [];
    for (let i: number = 0; i < num; i++) {
      elem.push({ id: i + 1 });
    }
    return elem;
  };
  const changeColumns = (sectionId: any, rowId: any, columnNumber: any) => {
    let NewScheme = JSON.parse(JSON.stringify(Schema));
    let currentScheme = NewScheme.layout.sections[sectionId - 1].rows.filter(
      (row: any) => {
        return row.id === rowId;
      }
    )[0];
    currentScheme.cols = renderElementSpace(columnNumber);
    setSchema(NewScheme);
  };
  const changeElementSkip = useCallback(
    (skip, section) => {
      let NewScheme = JSON.parse(JSON.stringify(Schema))
      if (NewScheme?.layout?.sections?.[section]) {
        NewScheme.layout.sections[section].skip = skip;
      }
      setSchema(NewScheme);
    },
    [Schema]
  );
  const changeElementNext = useCallback(
    (next, section) => {
      let NewScheme = JSON.parse(JSON.stringify(Schema))
      if (NewScheme?.layout?.sections?.[section]) {
        NewScheme.layout.sections[section]['next'] = next;
        console.log('here');
        console.log(NewScheme)
        setSchema(NewScheme);
      }
    },
    [Schema]
  );
  const changeElementProps = useCallback(
    (props, row, column, section) => {
      let NewScheme = JSON.parse(JSON.stringify(Schema))
      if (NewScheme?.layout?.sections?.[section]?.rows[row]?.cols[column]?.props) {
        NewScheme.layout.sections[section].rows[row].cols[column].props = props;
      }
      setSchema(NewScheme);
    },
    [Schema]
  );
  const addRow = (sectionId) => {
    let NewScheme = JSON.parse(JSON.stringify(Schema));
    const currentSection = NewScheme.layout.sections.filter(
      (item) => item.id === sectionId
    )[0];
    currentSection.rows.push({
      id: currentSection?.rows?.length + 1,
      cols: [{ id: 1 }],
    });
    setSchema(NewScheme);
  };
  const onDragStart = (prop) => {
    if (indices.includes(parseInt(prop.draggableId))) {
      toggleSectionContext(false);
    }
  };
  const handleCompoundChange = (section, row, col, val) => {
    let newVal = [...vals]
    // newVal.push(val?.value)
    newVal[col] = val?.value
    setVals(newVal)

    let NewScheme = JSON.parse(JSON.stringify(Schema));
    const allOptions = NewScheme.layout.sections[section].rows[row].cols[0].props.allOptions
    const lastLevel = getLastLevel(allOptions?.data?.data || [])
    for (let i = 0; i < lastLevel; i++) {
      NewScheme.layout.sections[section].rows[row].cols[i] = {}
      NewScheme.layout.sections[section].rows[row].cols[i].props = {
        allOptions,
        label: allOptions?.data?.label?.[i] || '',
        options: getLevel(allOptions?.data?.data || [], newVal || '', i + 1),
      };
      NewScheme.layout.sections[section].rows[row].cols[i].elementId =
        "25"
      NewScheme.layout.sections[section].rows[row].cols[i].element =
        "location"
      NewScheme.layout.sections[section].rows[row].cols[i].type =
        "location"
      NewScheme.layout.sections[section].rows[row].cols[i].id = i + 1
    }
    setActiveField({ elementId: 25, row, col, section });
    setSchema(NewScheme);
  }
  const onDragEnd = async (result: any) => {
    if (!sectionContext) {
      const elementID = result.draggableId;
      const element = elements.filter((el) => el.index == elementID)[0];
      if (!result?.destination?.droppableId?.[0]) return
      if (result?.destination?.droppableId !== "picker") {
        const destination = result.destination.droppableId.split('*')
        let NewScheme = JSON.parse(JSON.stringify(Schema));
        let section = parseInt(destination[0], 10) - 1;
        let row = parseInt(destination[1], 10) - 1;
        let col = parseInt(destination[2], 10) - 1;
        if (section == 0) {
          if (row == 0 || row == 1) {
            Notify("This is required and cannot be modified", {
              position: "top-right",
              status: "error",
            });
            return;
          }

        }
        const fieldId = (NewScheme.lastFieldId || 0) + 1;
        NewScheme.lastFieldId = fieldId;
        NewScheme.layout.sections[section].rows[row].cols[col]["type"] = element.type
        NewScheme.layout.sections[section].rows[row].cols[col]["element"] = element.element
        if (!NewScheme.layout.sections[section].rows[row].cols[col].props) {
          NewScheme.layout.sections[section].rows[row].cols[col].fieldId = fieldId;
          NewScheme.layout.sections[section].rows[row].cols[col].props = {};
          NewScheme.layout.sections[section].rows[row].cols[col].rules = {};
        }
        if (result.draggableId == 25) {
          NewScheme.layout.sections[section].rows[row].cols[col].elementId =
            result.draggableId;
          if (!NewScheme.layout.sections[section].rows[row].cols[col].props) {
            NewScheme.layout.sections[section].rows[row].cols[col].props = {
              label: 'Compound Selector',
              options: [],
              // options: getLevel(faculties,'',1),
              source: ''
            };
          }
          setActiveField({ elementId: result.draggableId, row, col, section });
          setSchema(NewScheme);
          return
        }
        if ([23, 27].includes(+result.draggableId)) {
          NewScheme.layout.sections[section].rows[row].cols[col].elementId =
            result.draggableId;
          if (!NewScheme.layout.sections[section].rows[row].cols[col].props) {
            NewScheme.layout.sections[section].rows[row].cols[col].props = {}
          }
          if(+result.draggableId === 23) {
              NewScheme.layout.sections[section].rows[row].cols[col].props.fileSize = 3;
              NewScheme.layout.sections[section].rows[row].cols[col].props.fileTypes = [
                { label: 'Image', value: 'Image' },
                { label: 'PDF', value: 'PDF' },
              ]
          }
          NewScheme.layout.sections[section].rows[row].cols[col].props.alternateLabel = +result.draggableId === 23 ? 'Attachment' : 'Photo';
          setActiveField({ elementId: result.draggableId, row, col, section });
          setSchema(NewScheme);
          return
        }
        if (result.draggableId == 26) {
          NewScheme.layout.sections[section].rows[row].cols[col].elementId =
            result.draggableId;
          if (!NewScheme.layout.sections[section].rows[row].cols[col].props.source) {
            const sourceUrl = `${urls?.FORM_API_BASE_URL}/data/location`;
            const { data: options } = await request({ method: 'get', url: sourceUrl })
            NewScheme.layout.sections[section].rows[row].cols[col].props = {
              source: sourceUrl,
              options: getLevel(options?.data?.data || [], '', 1),
              allOptions: options,
              label: options?.data?.label?.[0]
            };
          }
          setActiveField({ elementId: result.draggableId, row, col, section });
          setSchema(NewScheme);
          return
        }

        NewScheme.layout.sections[section].rows[row].cols[col].elementId =
          result.draggableId;
        if (!NewScheme.layout.sections[section].rows[row].cols[col].props) {
          NewScheme.layout.sections[section].rows[row].cols[col].props = {};
        }
        setActiveField({ elementId: result.draggableId, row, col, section, element: element.element, type: element.type });
        setSchema(NewScheme);
      }
      toggleSectionContext(true);
    } else {
      let NewScheme = JSON.parse(JSON.stringify(Schema));
      const { source, destination } = result;
      const newSections = swap(
        NewScheme.layout.sections,
        source.index,
        destination.index
      );
      NewScheme.layout.sections = newSections;
      setSchema(NewScheme);
      setActiveField({});
    }
  };
  function submitSchema() {
    const data = {
      tag: "tag 1",
      schema: Schema,
      type: "type 1",
      name: Schema.name,
      description: Schema.description,
      // id: Schema.id
    };
    setLoading(true)

    const url = `${urls?.FORM_API_BASE_URL}/designs/${id}`;
    request({ method: 'put', data, url }).then(() => {
      setLoading(false);
      return Notify("Form saved", {
        position: "top-right",
        status: "success",
      });
    })
      .catch(() => {
        Notify("Unable to save form", {
          position: "top-right",
          status: "error",
        });
      })
  }
  // please review logic//
  function handleSourceChoice(val) {
    val.value === "custom" ? setCustomData(true) : handleSourceChange(val);
  }

  async function handleSourceChange(val) {
    setCustomData(false);
    let NewScheme = JSON.parse(JSON.stringify(Schema));
    const { section, row, col } = activeField
    if (NewScheme.layout.sections[section].rows[row].cols[col].props) {
      NewScheme.layout.sections[section].rows[row].cols[col].props.source = val.value
      setSchema(NewScheme);
    }
  }

  function fileTypeChange(val) {
    let NewScheme = JSON.parse(JSON.stringify(Schema));
    const { section, row, col } = activeField
    if (NewScheme.layout.sections[section].rows[row].cols[col].props) {
      NewScheme.layout.sections[section].rows[row].cols[col].props.fileTypes = val
      setSchema(NewScheme);
    }
  }

  function fileSizeChange({ target: val }) {
    let NewScheme = JSON.parse(JSON.stringify(Schema));
    const { section, row, col } = activeField
    if (NewScheme.layout.sections[section].rows[row].cols[col].props) {
      NewScheme.layout.sections[section].rows[row].cols[col].props.fileSize = val.value
      setSchema(NewScheme);
    }
  }

  function handleUserDuplicateRowToggle(val) {
    let NewScheme = JSON.parse(JSON.stringify(Schema));
    const { section, row } = activeField
    NewScheme.layout.sections[section].rows[row].canDuplicate = val
    setSchema(NewScheme);
  }

  function handleAddRowAlias({ target: { value: val } }) {
    let NewScheme = JSON.parse(JSON.stringify(Schema));
    const { section, row } = activeField
    NewScheme.layout.sections[section].rows[row].addRowAlias = val
    setSchema(NewScheme);
  }

  function handleMaxRow({ target: { value: val } }) {
    let NewScheme = JSON.parse(JSON.stringify(Schema));
    const { section, row } = activeField
    NewScheme.layout.sections[section].rows[row].maxDuplicate = Math.abs(val)
    setSchema(NewScheme);
  }

  function handleUserDuplicateSectionToggle(val, sec) {
    let NewScheme = JSON.parse(JSON.stringify(Schema));
    const { section } = sec
    NewScheme.layout.sections[section].canDuplicate = val
    setSchema(NewScheme);
  }

  function handleAddSectionAlias(val, sec) {
    let NewScheme = JSON.parse(JSON.stringify(Schema));
    const { section } = sec
    NewScheme.layout.sections[section].addSectionAlias = val
    setSchema(NewScheme);
  }

  function handleMaxSection(val, sec) {
    let NewScheme = JSON.parse(JSON.stringify(Schema));
    const { section } = sec
    NewScheme.layout.sections[section].maxDuplicate = Math.abs(val)
    setSchema(NewScheme);
  }

  function handleRefereeSection(val, sec) {
    let NewScheme = JSON.parse(JSON.stringify(Schema));
    const { section } = sec;
    NewScheme.layout.sections[section].refereeSection = val
    if (val === false) {
      if (NewScheme.layout.sections[section].refereeSectionType) {
        NewScheme.layout.sections[section].refereeSectionType = '';
        NewScheme.layout.sections[section].rows = [{ id: 1, cols: [{ id: 1 }] }];
      }
    }
    setSchema(NewScheme);
  }

  function handleRefereeSectionType(val, sec) {
    let NewScheme = JSON.parse(JSON.stringify(Schema));
    const { section } = sec
    if (NewScheme.layout.sections[section].rows[0]?.cols[0]?.type) {
      setRefereeModal({
        open: true,
        refereeValue: val,
        sec
      });
    } else {
      handleRefereeModal(val, sec);
    }
  }

  function handleRefereeModal(val, sec) {
    let NewScheme = JSON.parse(JSON.stringify(Schema));
    const { section } = sec;
    NewScheme.layout.sections[section].refereeSectionType = val;
    if (val.toLowerCase() === 'applicant') {
      NewScheme.layout.sections[section].rows = [refreeDefaultFields]
    } else {
      NewScheme.layout.sections[section].rows = [{ id: 1, cols: [{ id: 1 }] }];
    }
    setSchema(NewScheme);
  }

  const handleTitle = (name) => {
    let NewScheme = { ...Schema }
    NewScheme.name = name
    setSchema(NewScheme)
  }
  const handelDescription = (desc) => {
    let NewScheme = { ...Schema }
    NewScheme.description = desc
    setSchema(NewScheme)
  }
  const getDirtyFields = () => {
    const items: string[] = [];
    Schema.layout.sections.forEach((sec, index) => {
      if (!sec?.title) {
        items.push(`Section ${index + 1} Title`);
      }
      sec?.rows?.forEach((row, rowIndex) => {
        row?.cols?.forEach((col, colIndex) => {
          if (!col.props?.label && col.elementId) {
            items.push(`The label of element in Section ${index + 1}, Row ${rowIndex + 1}, Column ${colIndex + 1}`);
          }
        })
      })
    })
    return items;
  }
  if (loading) {
    return (
      <Box vAlign height={`calc(100vh - 200px)`}>
        <Box width="200px" margin="0 auto" align="center">
          <Loader />
        </Box>
      </Box>
    )
  }
  if (error?.message || !localStorage.getItem("access_token") || !localStorage.getItem("refresh_token")) {
    return (<EmptyState
      type="users"
      title="Error"
      info={error?.message}
    />)
  }
  const dirtyItems = getDirtyFields();
  return (
    <Layout style={{ minWidth: '99vw !important' }} theme={Theme}>
      <GridLayout collapseLeft={false} collapseRight={false}>
        <TopSide>
          <Box pad="15px 150px 15px 15px" align="left" style={{ width: '800px' }}>
            <HiddenInput
              type="text"
              bold
              placeholder=""
              value={Schema.name || ""}
              onChange={(e) => handleTitle(e.target.value)}
            />
            <HiddenInput
              type="text"
              placeholder=""
              value={Schema.description || ""}
              onChange={(e) => handelDescription(e.target.value)}
            />
          </Box>
          <Box pad="15px 20px" align="right">
            <Button onClick={() => togglePreview(true)} color="#555">Preview</Button>
            <Button spaceLeft onClick={() => {
              if (dirtyItems?.length > 0) {
                setInstruction(true);
                return;
              }
              submitSchema()
            }}>
              Save
            </Button>
          </Box>
        </TopSide>
        <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onDragStart}>
          <LeftSide>
            <Box width="250px" pad="20px">
              <Text bold block>
                Form Elements
              </Text>
              <Spacer space="10px" />
              <Droppable droppableId="picker">
                {(provided) => {
                  return (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {elements.map((element, index) => {
                        return (
                          <Draggable
                            draggableId={`${element.index}`}
                            index={index}
                            key={index}
                          >
                            {(provided) => {
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  {...props}
                                >
                                  <DragElement key={index} {...element} />
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </Box>
          </LeftSide>
          <RightSide>
            {
              activeField?.elementId && (
                <>
                  <Box width="250px" pad="20px 20px 10px 20px">
                    <Text bold block>
                      {keyedElements?.[activeField?.elementId]?.label} Form Properties
                    </Text>
                  </Box>
                  <Tabs bgColor="none" justifyTabs activeTab={0}>
                    <TabWrapper label="General">
                      <ElementProperties
                        activeField={activeField}
                        schema={Schema}
                        changeProps={changeElementProps}
                        changeElementSkip={changeElementSkip}
                      />
                    </TabWrapper>
                    {activeField && [23, 25].includes(parseInt(activeField.elementId)) ? <TabWrapper label="Advanced">
                      <>
                        {
                          parseInt(activeField?.elementId) === 25 && (
                            <>
                              <Select
                                onChange={(e: any) => handleSourceChoice(e)}
                                options={options}
                                label="data Source"
                                spaceTop
                                block
                              />
                              {customeData && <CustomDataSource />}
                            </>
                          )
                        }
                        {
                          parseInt(activeField?.elementId) === 23 && (
                            <>
                              <Select
                                onChange={(val: any) => fileTypeChange(val)}
                                options={[
                                  { label: 'Image', value: 'Image' },
                                  { label: 'PDF', value: 'PDF' },
                                  { label: 'Audio', value: 'Audio' },
                                  { label: 'Video', value: 'Video' }
                                ]}
                                isMulti
                                label="File Type"
                                spaceTop
                                block
                                value={Schema?.layout?.sections[activeField?.section]?.rows[activeField?.row]?.cols[activeField?.col]?.props?.fileTypes}
                              />
                              <Input
                                label="Max File Size (in megabytes)"
                                type="number"
                                min="0"
                                spaceTop
                                spaceBottom="10px"
                                required
                                height="36px"
                                onChange={fileSizeChange}
                                value={Schema?.layout?.sections[activeField?.section]?.rows[activeField?.row]?.cols[activeField?.col]?.props?.fileSize}
                                block
                              />
                            </>
                          )
                        }
                      </>
                    </TabWrapper>
                      : <TabWrapper label="Advanced" disabled></TabWrapper>
                    }

                    <TabWrapper label="Settings">
                      <Box margin="20px 15px">
                        <Text bold block>
                          Row Settings
                        </Text>
                        <Spacer space="20px" />
                        <Text block>
                          Allow candidate duplicate row
                        </Text>
                        <Spacer space="5px" />
                        <RadioButton
                          name="row"
                          checked={Schema?.layout?.sections[activeField?.section]?.rows[activeField?.row].canDuplicate}
                          onChange={() => handleUserDuplicateRowToggle(true)}
                          spaceLeft="-10px"
                          size="20px"
                          label="Yes"
                          />
                        <RadioButton
                          name="row"
                          checked={!Schema?.layout?.sections[activeField?.section]?.rows[activeField?.row].canDuplicate}
                          onChange={() => handleUserDuplicateRowToggle(false)}
                          spaceLeft="20px"
                          size="20px"
                          label="No"
                        />

                        <Spacer space="25px" />
                        <Text block>
                          Add row alias
                        </Text>
                        <Input
                          spaceTop
                          spaceBottom="10px"
                          required
                          height="36px"
                          disabled={!Schema?.layout?.sections[activeField?.section]?.rows[activeField?.row].canDuplicate}
                          onChange={handleAddRowAlias}
                          value={Schema?.layout?.sections[activeField?.section]?.rows[activeField?.row].addRowAlias || ''}
                          block
                        />
                        <Spacer space="25px" />
                        <Text block>
                          Maximum number of duplicate rows
                        </Text>
                        <Input
                          spaceTop
                          spaceBottom="10px"
                          required
                          height="36px"
                          min="0"
                          type="number"
                          disabled={!Schema?.layout?.sections[activeField?.section]?.rows[activeField?.row].canDuplicate}
                          onChange={handleMaxRow}
                          value={Schema?.layout?.sections[activeField?.section]?.rows[activeField?.row].maxDuplicate || ''}
                          block
                        />
                      </Box>
                      <Box margin="30px 15px 20px">
                        <Text bold block>
                          Section Settings
                        </Text>
                        <Spacer space="20px" />
                        <Text block>
                          Allow candidate duplicate section
                        </Text>
                        <Spacer space="5px" />
                        <RadioButton
                          name="section"
                          checked={Schema?.layout?.sections[activeField?.section]?.canDuplicate}
                          onChange={() => handleUserDuplicateSectionToggle(true, activeField)}
                          spaceLeft="-10px"
                          size="20px"
                          label="Yes"
                        />
                        <RadioButton
                          name="section"
                          checked={!Schema?.layout?.sections[activeField?.section]?.canDuplicate}
                          onChange={() => handleUserDuplicateSectionToggle(false, activeField)}
                          spaceLeft="20px"
                          size="20px"
                          label="No"
                        />
                        
                        <Spacer space="25px" />
                        <Text block>
                          Add section alias
                        </Text>
                        <Input
                          spaceTop
                          spaceBottom="10px"
                          required
                          height="36px"
                          disabled={!Schema?.layout?.sections[activeField?.section]?.canDuplicate}
                          onChange={(e) => handleAddSectionAlias(e.target.value, activeField)}
                          value={Schema?.layout?.sections[activeField?.section]?.addSectionAlias || ''}
                          block
                        />
                        <Spacer space="25px" />
                        <Text block>
                          Maximum number of duplicate sections
                        </Text>
                        <Input
                          spaceTop
                          spaceBottom="10px"
                          required
                          disabled={!Schema?.layout?.sections[activeField?.section]?.canDuplicate}
                          height="36px"
                          onChange={(e) => handleMaxSection(e.target.value, activeField)}
                          value={Schema?.layout?.sections[activeField?.section]?.maxDuplicate || ''}
                          block
                        />
                        <Spacer space="20px" />
                        <Text block>
                          Is this a Referee section?
                        </Text>
                        <RadioButton
                          name="referee"
                          checked={Schema?.layout?.sections[activeField?.section]?.refereeSection}
                          onChange={() => handleRefereeSection(true, activeField)}
                          spaceLeft="-10px"
                          size="20px"
                          label="Yes"
                        />
                        <RadioButton
                          name="referee"
                          checked={!Schema?.layout?.sections[activeField?.section]?.refereeSection}
                          onChange={() => handleRefereeSection(false, activeField)}
                          spaceLeft="20px"
                          size="20px"
                          label="No"
                        />
                        {Schema?.layout?.sections[activeField?.section]?.refereeSection && 
                          <>
                            <Spacer space="20px" />
                            <Select
                              onChange={(val: any) => handleRefereeSectionType(val?.value, activeField)}
                              options={[
                                { label: 'Applicant', value: 'Applicant' },
                                { label: 'Referee', value: 'Referee' }
                              ]}
                              label="Who is this section meant for?"
                              spaceTop
                              block
                              placeholder="Select..."
                              disabled={!Schema?.layout?.sections[activeField?.section]?.refereeSection}
                              value={!Schema?.layout?.sections[activeField?.section]?.refereeSectionType ? '' : 
                              {
                                label: Schema?.layout?.sections[activeField?.section]?.refereeSectionType, 
                                value: Schema?.layout?.sections[activeField?.section]?.refereeSectionType
                              }}
                            />
                          </>
                        }
                      </Box>
                    </TabWrapper>
                  </Tabs>
                  {
                    +(dirtyItems?.length) > 0 && (
                      <Box width="250px" pad="20px 20px 10px 20px">
                        <Text bold block>
                          Update the following labels/titles to save your form.
                        </Text>
                        <Spacer space="15px" />
                        <Text block color={Theme.PrimaryRed} style={{ fontStyle: 'italic', marginLeft: '-20px' }}>
                          <ol>
                            {
                              dirtyItems.map((item, i) => <li key={i}>{item}</li>)
                            }
                          </ol>
                        </Text>
                      </Box>
                    )
                  }
                </>
              )
            }
            {
              activeSection?.section && (
                <Box width="250px" pad="20px 20px 10px 20px">
                  <Box margin="0">
                    <Text bold block>
                      Section Settings
                    </Text>
                    <Spacer space="20px" />
                    <Text block>
                      Allow candidate duplicate section
                    </Text>
                    <Spacer space="5px" />
                    <RadioButton
                      name="section"
                      checked={Schema?.layout?.sections[activeSection?.section]?.canDuplicate}
                      onChange={() => handleUserDuplicateSectionToggle(true, activeSection)}
                      spaceLeft="-10px"
                      size="20px"
                      label="Yes"
                    />
                    <RadioButton
                      name="section"
                      checked={!Schema?.layout?.sections[activeSection?.section]?.canDuplicate}
                      onChange={() => handleUserDuplicateSectionToggle(false, activeSection)}
                      spaceLeft="20px"
                      size="20px"
                      label="No"
                    />
                    
                    <Spacer space="25px" />
                    <Text block>
                      Add section alias
                    </Text>
                    <Input
                      spaceTop
                      spaceBottom="10px"
                      required
                      height="36px"
                      disabled={!Schema?.layout?.sections[activeSection?.section]?.canDuplicate}
                      onChange={(e) => handleAddSectionAlias(e.target.value, activeSection)}
                      value={Schema?.layout?.sections[activeSection?.section]?.addSectionAlias || ''}
                      block
                    />
                    <Spacer space="25px" />
                    <Text block>
                      Maximum number of duplicate sections
                    </Text>
                    <Input
                      spaceTop
                      spaceBottom="10px"
                      required
                      disabled={!Schema?.layout?.sections[activeSection?.section]?.canDuplicate}
                      height="36px"
                      onChange={(e) => handleMaxSection(e.target.value, activeSection)}
                      value={Schema?.layout?.sections[activeSection?.section]?.maxDuplicate || ''}
                      block
                    />
                    <Spacer space="20px" />
                    <Text block>
                      Is this a Referee section?
                    </Text>
                    <RadioButton
                      name="referee"
                      checked={Schema?.layout?.sections[activeSection?.section]?.refereeSection}
                      onChange={() => handleRefereeSection(true, activeSection)}
                      spaceLeft="-10px"
                      size="20px"
                      label="Yes"
                    />
                    <RadioButton
                      name="referee"
                      checked={!Schema?.layout?.sections[activeSection?.section]?.refereeSection}
                      onChange={() => handleRefereeSection(false, activeSection)}
                      spaceLeft="20px"
                      size="20px"
                      label="No"
                    />
                    {Schema?.layout?.sections[activeSection?.section]?.refereeSection && 
                      <>
                        <Spacer space="20px" />
                        <Select
                          onChange={(val: any) => handleRefereeSectionType(val?.value, activeSection)}
                          options={[
                            { label: 'Applicant', value: 'Applicant' },
                            { label: 'Referee', value: 'Referee' }
                          ]}
                          label="Who is this section meant for?"
                          spaceTop
                          block
                          placeholder="Select..."
                          disabled={!Schema?.layout?.sections[activeSection?.section]?.refereeSection}
                          value={!Schema?.layout?.sections[activeSection?.section]?.refereeSectionType ? '' : 
                          {
                            label: Schema?.layout?.sections[activeSection?.section]?.refereeSectionType, 
                            value: Schema?.layout?.sections[activeSection?.section]?.refereeSectionType
                          }}
                        />
                      </>
                    }
                  </Box>
                </Box>
              )
            }
            <Modal open={refereeModal?.open} center={false}>
              <ModalBody width="500px">
                <Box pad="30px" align="center">
                  <Text>
                    Changing this field will reset/clear everything done on this section.<br /> Do you wish to continue?
                  </Text>
                </Box>
                <ModalFooter>
                  <Box pad="10px" align="right">
                    <Button
                      spaceRight
                      height={32}
                      pale
                      color="#aaa"
                      fontColor="#999"
                      onClick={() => setRefereeModal({...refereeModal, open: false})}
                    >
                      Cancel
                    </Button>
                    <Button
                      height={32}
                      onClick={() => {
                        handleRefereeModal(refereeModal?.refereeValue, refereeModal?.sec);
                        setRefereeModal({...refereeModal, open: false})
                      }}
                    >
                      Continue
                    </Button>
                  </Box>
                </ModalFooter>
              </ModalBody>
            </Modal>
          </RightSide>
          <MainPanel>
            {!sectionContext ? (
              <Box pad="20px" maxWidth="1000px" centered>
                {Schema?.layout?.sections &&
                  Schema.layout.sections.map((section) => {
                    return (
                      <SectionElement
                        section={section}
                        addRow={addRow}
                        changeColumns={(sectionId, id, columnNumber) =>
                          changeColumns(sectionId, id, columnNumber)
                        }
                        schema={Schema}
                        setSchema={setSchema}
                        activeField={activeField}
                        setActiveField={setActiveField}
                        key={section.id}
                        handleCompoundChange={handleCompoundChange}
                      />
                    );
                  })}
                <Spacer space="20px" />
                <Box align="center">
                  <Button
                    iconLeft={<i className="form-down" />}
                    onClick={() => addSection()}
                  >
                    Add Section
                  </Button>
                </Box>
                <Spacer space="20px" />
                <Fader />
              </Box>
            ) : (
              <Droppable droppableId="main">
                {(provided) => {
                  return (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <Box pad="20px" maxWidth="1000px" centered>
                        {Schema?.layout?.sections &&
                          Schema.layout.sections.map((section, index) => {
                            return (
                              <Draggable
                                draggableId={`${section.id}`}
                                index={index}
                                key={index}
                              >
                                {(provided) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      {...props}
                                    >
                                      <SectionElement2
                                        section={section}
                                        addRow={addRow}
                                        changeColumns={(
                                          sectionId,
                                          id,
                                          columnNumber
                                        ) =>
                                          changeColumns(
                                            sectionId,
                                            id,
                                            columnNumber
                                          )
                                        }
                                        changeElementNext={changeElementNext}
                                        schema={Schema}
                                        setSchema={setSchema}
                                        activeField={activeField}
                                        setActiveField={(data) => {
                                          setActiveField(data);
                                          setActiveSection({});
                                        }}
                                        setActiveSection={(data) => {
                                          setActiveSection(data);
                                          setActiveField({});
                                        }}
                                        key={section.id}
                                        handleCompoundChange={handleCompoundChange}
                                      />
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                        <Spacer space="20px" />
                        <Box align="center">
                          <Button
                            iconLeft={<i className="form-down" />}
                            onClick={() => addSection()}
                            color="#5D9C5A"
                          >
                            Add Section
                          </Button>
                        </Box>
                        <Spacer space="20px" />
                        <Fader />
                      </Box>
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            )}
          </MainPanel>
        </DragDropContext>
      </GridLayout>
      <Preview open={preview} close={() => togglePreview(false)}
        schema={Schema.name ? Schema : {}}
      />
      <Instruction open={instruction} close={() => setInstruction(false)} />
    </Layout>
  );
};

export const TabWrapper = (props) => {
  return <div {...props} />;
};
export default App;
