import React, { useState } from "react";
import styled, { css } from "styled-components";
import { FormField } from "./FormField";
import { TinyMCEKey } from "../util/constants";
import { Editor } from '@tinymce/tinymce-react';

import {
  Box,
  DropDown,
  Button,
  GridRow,
  Modal,
  ModalBody,
  ModalClose,
  ModalFooter,
  Text,
  Notify,
  Spacer,
  Select
} from "@flexisaf/flexibull2";
import { transparentize, darken } from "polished";

export const SectionWrapper = styled("div") <any>`
  padding: 20px 0px;
  & > div {
    ${(props) => props.theme.Elevate.mid};
  }
`;

export const SectionHeader = styled("div") <any>`
  padding: 20px;
  border-bottom: 1px dotted
    ${(props) => transparentize(0.3, props.theme.PrimaryBorderColor)};
  display: grid;
  grid-template-columns: 50px auto 20px;
  grid-gap: 20px;

  & .section-icon {
    display: grid;
    justify-content: center;
    & .section-icon-holder {
      width: 50px;
      height: 50px;
      border: 1px dashed
        ${(props) => darken(0.1, props.theme.PrimaryBorderColor)};
      border-radius: 50%;
      padding: 5px;
      display: flex;
      color: #fff;
      align-items: center;
      justify-content: center;
      position: relative;
      & i {
        position: relative;
        z-index: 2;
        font-size: 16px;
      }

      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 80%;
        height: 80%;
        top: 10%;
        left: 10%;
        border-radius: 50%;
        background: ${(props) => darken(0.2, props.theme.PrimaryBorderColor)};
        z-index: 1;
      }
    }
  }
`;

export const Row = styled("div") <any>`
    padding: 10px 25px;
    position: relative;
    background: #fff;
    transition: all 0.3s ease-out;

    & .form-dot-3{
        opacity:0.6;
        &:hover{
            opacity: 1;
        }
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left:0;
      width: 3px;
      height: 0;
      background: linear-gradient(${(props) => props.theme.PrimaryColor}, ${(
  props
) => props.theme.PrimaryGreen});
      transition: all 0.3s ease-out;
      opacity: 0.8;
    }
    &:hover{
      /* ${(props) => props.theme.Elevate.fader}; */
      &:after{
        height: 100%;
        top:0;
      }
      background-color: #cccccc26;
    }
`;

export const MiniButton = styled("div") <any>`
  width: 20px;
  height: 20px;
  border-radius: ${(props) => props.theme.PrimaryRadius};
  display: inline-grid;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
  vertical-align: top;

  ${(props) =>
    props.rounded &&
    css`
      border-radius: 50%;
      height: 24px;
      width: 24px;
      border: 1px dotted ${(props) => props.theme.PrimaryBorderColor};
      position: relative;
      margin-right: 20px;
      cursor: grab;
      &:after {
        content: "";
        display: block;
        height: 18px;
        width: 18px;
        position: absolute;
        border-radius: 50%;
        background: ${(props) => props.theme.PrimaryBorderColor};
        top: 2px;
        left: 2px;
        z-index: 0;
      }
      & i {
        position: relative;
        z-index: 1;
        font-size: 11px;
        color: #fff;
      }
    `}
  &:hover {
    background-color: ${(props) => props.theme.PrimaryFade};
  }
`;

export const DropZone = styled("div") <any>`
  border-radius: ${(props) => props.theme.PrimaryRadius};
  background-color: ${(props) =>
    transparentize(0.6, props.theme.PrimaryBorderColor)};
  min-height: 48px;
  padding: 10px;
  display: grid;
  align-items: center;
  margin: 5px 0;
  &:focus-within {
    background-color: ${(props) =>
    transparentize(0.6, props.theme.PrimaryColor)};
  }
  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) =>
        transparentize(0.6, props.theme.PrimaryColor)};
    `}
`;

export const HiddenInput = styled("input") <any>`
  display: block;
  padding: 5px 10px;
  border-radius: ${(props) => props.theme.PrimaryRadius};
  color: ${(props) => props.theme.PrimaryFontColor};
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  width: 100%;
  ${(props) =>
    props.bold &&
    `
        font-size: 18px;
        font-weight: bold;
    `}
  &:focus {
    outline: none;
    border: 1px solid
      ${(props) => transparentize(0, props.theme.PrimaryBorderColor)};
    color: #333;
  }
`;

export const Fader = styled("div") <any>`
  height: 100px;
  border-radius: ${(props) => props.theme.PrimaryRadius};
  background: linear-gradient(
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`;

export const RowGrid = styled("div") <any>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.children.length}, 1fr);
  grid-gap: 10px;
`;

export const Laybutton = styled.div`
  margin-right: 15px;
  height: 20px;
  width: 26px;
  display: inline-grid;
  grid-template-columns: repeat(${(props: any) => props.children.length}, 1fr);
  grid-gap: 2px;
  cursor: pointer;
  & div {
    border-radius: 3px;
    border: 1px solid
      ${(props) => transparentize(0.3, props.theme.PrimaryBorderColor)};
    transition: 0.3s ease-out;
  }
  &:hover {
    & div {
      border: 1px solid
        ${(props) => transparentize(0, props.theme.PrimaryColor)};
    }
  }
`;

export const RowElement = ({
  cols,
  id: rowId,
  sectionId,
  changeColumns,
  schema,
  activeField,
  setActiveField,
  onDelete,
  defaultSection,
  onDuplicateRow,
  handleCompoundChange
}) => {
  const [tempColumns, setTempColumns] = useState();
  const [modal, setModal] = useState(false);
  const RowMenu = [
    {
      onClick: onDuplicateRow,
      label: "Duplicate Row",
      iconLeft: "form-docs",
    },
    {
      onClick: onDelete,
      label: "Delete Row",
      iconLeft: "form-trash-empty",
    },
  ];

  const changeLayout = (cols) => {
    let hasChild = false;
    const newSchema = JSON.parse(JSON.stringify(schema));
    newSchema.layout?.sections?.[sectionId - 1]?.rows[rowId - 1]?.cols.forEach(
      (col) => {
        if (col.elementId) {
          hasChild = true;
        }
      }
    );
    if (!hasChild) {
      changeColumns(sectionId, rowId, cols);
    } else {
      setTempColumns(cols);
      setModal(true);
    }
  };

  return (
    <>
      <Row>
        <GridRow>
          <Box span={4} stlye={{ verticalAlign: "top" }}>
            <MiniButton rounded>
              <i className="form-move-1" />
            </MiniButton>
          </Box>
          {
            !defaultSection ? (
              <Box span={8} align="right">
                <Laybutton onClick={() => changeLayout(1)}>
                  <div />
                </Laybutton>
                <Laybutton onClick={() => changeLayout(2)}>
                  <div />
                  <div />
                </Laybutton>
                <Laybutton onClick={() => changeLayout(3)}>
                  <div />
                  <div />
                  <div />
                </Laybutton>
                <DropDown
                  style={{ textAlign: "left", verticalAlign: "top" }}
                  label={
                    <MiniButton>
                      <i
                        className="form-dot-3"
                        style={{ display: "block", transform: "rotateZ(90deg)" }}
                      />
                    </MiniButton>
                  }
                  menuList={RowMenu}
                  menuAlign="right bottom"
                />
              </Box>
            ) : <Box span={8} align="right" />
          }
        </GridRow>
        <RowGrid>
          {cols.map((column) => {
            return (
              <DropZone
                active={
                  sectionId - 1 === activeField.section &&
                  rowId - 1 === activeField.row &&
                  column.id - 1 === activeField.col
                }
                key={column.id}
              >
                {column.type ? (
                  <div
                    onClick={() => {
                      setActiveField({
                        elementId: column.elementId,
                        row: rowId - 1,
                        col: column.id - 1,
                        section: sectionId - 1,
                        element: column.element,
                        type: column.type,
                        defaultSection: defaultSection || false
                      });
                    }}
                  >
                    <FormField
                      element={column}
                      row={rowId}
                      col={column.id}
                      activeField={activeField}
                      schema={schema}
                      section={sectionId}
                    />
                  </div>
                ) : (
                  <Box align="center">Drop Element here</Box>
                )}
              </DropZone>
            );
          })}
        </RowGrid>
      </Row>
      {modal && (
        <Modal open={modal} center={false}>
          <ModalBody width="300px">
            <Box pad="30px">
              <Text>
                Changing the a Row layout after adding a field element will
                reset the Row contents. <br /> Do you wish to continue?
              </Text>
            </Box>
            <ModalFooter>
              <Box pad="10px" align="right">
                <Button
                  spaceRight
                  height={32}
                  pale
                  color="#aaa"
                  fontColor="#999"
                  onClick={() => setModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  height={32}
                  onClick={() => {
                    setModal(false);
                    setActiveField({});
                    changeColumns(sectionId, rowId, tempColumns);
                  }}
                >
                  Continue
                </Button>
              </Box>
            </ModalFooter>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export const SectionElement2 = ({
  section,
  addRow,
  changeColumns,
  schema,
  setSchema,
  activeField,
  setActiveField,
  setActiveSection,
  handleCompoundChange,
  changeElementNext = () => null
}: any) => {
  const [openNextModal, setOpenNextModal] = useState(false);
  const [sectionDescriptionModal, setSectionDescriptionModal] = useState(false);
  const handleSectionTitle = (title) => {
    const newSchema = JSON.parse(JSON.stringify(schema));
    newSchema.layout.sections[section.id - 1].title = title;
    setSchema(newSchema);
  };
  const handleSectionDescription = (description) => {
    const newSchema = JSON.parse(JSON.stringify(schema));
    newSchema.layout.sections[section.id - 1].description = description;
    setSchema(newSchema);
  };

  const handleDeleteSection = () => {
    const newSchema = JSON.parse(JSON.stringify(schema));

    newSchema.layout.sections = newSchema.layout.sections
      .filter(sectionContent => sectionContent.id !== section.id)
      .map((sectionContent, index) => ({ ...sectionContent, id: index + 1 }));

    setSchema(newSchema);
  }

  const getSections = () => {
    return schema?.layout?.sections?.filter((_, ind) => (ind >= section.id))?.map(sec => ({ label: sec.title, value: sec.id }));
  }

  const handleDuplicateSection = () => {
    const newSchema = JSON.parse(JSON.stringify(schema));
    const schemaSection = newSchema.layout.sections;
    const sectionToDuplicateIndex = section.id - 1;
    let lastFieldId = newSchema.lastFieldId;
    const duplicateSection = JSON.parse(JSON.stringify(schemaSection[sectionToDuplicateIndex]));
    duplicateSection.rows = duplicateSection.rows.map(row => {
      const rowCols = row.cols.map(col => {
        lastFieldId = lastFieldId + 1
        return { ...col, fieldId: lastFieldId }
      });
      return { ...row, cols: rowCols }
    });
    duplicateSection.title = `${duplicateSection.title} (COPY)`;
    schemaSection.splice(sectionToDuplicateIndex + 1, 0, duplicateSection);

    newSchema.layout.sections = schemaSection.map((sectionContent, index) => ({ ...sectionContent, id: index + 1 }));
    newSchema.lastFieldId = lastFieldId;
    setSchema(newSchema);
    Notify("Section Duplicated Successfully");
  }

  const handleActiveSection = () => {
    setActiveSection({section: section.id - 1,});
  }

  const SectionMenu = (isDefault) => ([
    {
      onClick: handleDuplicateSection,
      label: "Duplicate Section",
      iconLeft: "form-docs",
      showDefault: false
    },
    {
      onClick: handleDeleteSection,
      label: "Delete Section",
      iconLeft: "form-trash-empty",
      showDefault: false
    },
    {
      onClick: () => setOpenNextModal(true),
      label: "Choose Next",
      iconLeft: "form-trash-empty",
      showDefault: true
    },
    {
      onClick: () => handleActiveSection(),
      label: "Section Settings",
      iconLeft: "form-cog",
      showDefault: false
    },
  ].filter(a => isDefault ? a.showDefault : true));

  const handleDeleteRow = (id) => {
    const newSchema = JSON.parse(JSON.stringify(schema));
    const currentRows = newSchema.layout.sections[section.id - 1].rows
      .filter(rowContent => rowContent.id !== id)
      .map((rowContent, index) => ({ ...rowContent, id: index + 1 }));
    newSchema.layout.sections[section.id - 1].rows = currentRows;
    setSchema(newSchema);
  }

  const handleDuplicateRow = (id) => {
    const newSchema = JSON.parse(JSON.stringify(schema));
    let sectionRows = [...newSchema.layout.sections[section.id - 1].rows];
    const rowToDuplicateIndex = sectionRows.findIndex(r => r.id === id);
    const duplicateRow = JSON.parse(JSON.stringify(sectionRows[rowToDuplicateIndex]));
    duplicateRow.cols = duplicateRow.cols.map((elem, i) => ({ ...elem, fieldId: (newSchema.lastFieldId + i + 1) }))
    sectionRows.splice(rowToDuplicateIndex, 0, duplicateRow);

    sectionRows = sectionRows.map((r, index) => ({ ...r, id: index + 1 }));

    newSchema.lastFieldId = newSchema.lastFieldId + duplicateRow.cols.length;
    newSchema.layout.sections[section.id - 1].rows = sectionRows;
    setSchema(newSchema);
  }

  // const getMappingOptions = (i) => {
  //   // getSections()[schema?.layout?.sections?.[section.id]?.next]
  //   const mappedOptions = skipMapping.map(a => a[0]).filter((a, ai) => a && ai !== i);
  //   return getSections().filter(a => !mappedOptions.includes(`${a.value}`));
  // }
  return (
    <SectionWrapper>
      <Box round="high" background="#fff" elevate="high">
        <SectionHeader>
          <div className="section-icon">
            <div className="section-icon-holder">
              <i className="form-move-1" />
            </div>
          </div>
          <Box>
            <HiddenInput
              type="text"
              bold
              placeholder="Section Title"
              value={section.title || ""}
              onChange={(e) => handleSectionTitle(e.target.value)}
            />
            <p
              style={{ border: "1px solid rgba(216,216,227,0.4)", padding: "8px", cursor: "text", minHeight: "20px" }}
              onClick={() => setSectionDescriptionModal(true)}
              dangerouslySetInnerHTML={{
                __html: section.description || '',
              }}
            ></p>
          </Box>
          <Box>
            <DropDown
              label={
                <MiniButton>
                  <i
                    className="form-dot-3"
                    style={{ display: "block", transform: "rotateZ(90deg)" }}
                  />
                </MiniButton>
              }
              menuList={SectionMenu(section.default)}
              menuAlign="right bottom"
            />
          </Box>
        </SectionHeader>
        <Box pad="30px 0">
          {section.rows.map((row: any) => {
            return (
              <RowElement
                key={row.id}
                changeColumns={changeColumns}
                sectionId={section.id}
                {...row}
                onDelete={() => handleDeleteRow(row.id)}
                onDuplicateRow={() => handleDuplicateRow(row.id)}
                schema={schema}
                defaultSection={row.default}
                activeField={activeField}
                setActiveField={setActiveField}
                handleCompoundChange={handleCompoundChange}
              />
            );
          })}
          <Box align="center">
            <Button
              iconLeft={<i className="form-down" />}
              onClick={() => addRow(section.id)}
            >
              Add Row
            </Button>
          </Box>
        </Box>
      </Box>

      {
        sectionDescriptionModal && (
          <Modal open={sectionDescriptionModal} center={false}>
            <ModalBody width="800px">
              <Box pad="30px">
                <Editor
                  init={{
                    resize: false,
                    height: 500,
                    menubar: false,
                    plugins: [
                      'advlist',
                      'autolink',
                      'lists',
                      'link',
                      'image',
                      'charmap',
                      'preview',
                      'anchor',
                      'searchreplace',
                      'visualblocks',
                      'fullscreen',
                      'insertdatetime',
                      'media',
                      'table',
                      'wordcount',
                      'codesample',
                      'code',
                      'accordion',
                      'pagebreak'
                    ],
                    toolbar:
                      'casechange blocks fontfamily fontsizeinput bold italic underline accordion image strikethrough forecolor backcolor align lineheight bullist numlist outdent indent removeformat link table media pagebreak code',
                    file_browser_callback_types: 'image',
                    file_picker_callback: function (cb, value, meta) {
                      if (meta.filetype === 'image') {
                        var input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');
                        input.click();
                        input.onchange = function () {
                          if (!input.files) return;
                          var file = input.files[0];
                          var reader = new FileReader();
                          reader.onload = function (e) {
                            if (!e.target) return;
                            cb(JSON.stringify(e.target.result), {
                                alt: file.name
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                  }}
                  apiKey={TinyMCEKey}
                  value={section.description || ""}
                  onEditorChange={v => handleSectionDescription(v)}
                />
              </Box>
              <ModalFooter>
                <Box pad="10px" align="right">
                  <Button
                    height={32}
                    onClick={() => setSectionDescriptionModal(false)}
                  >
                    Close
                  </Button>
                </Box>
              </ModalFooter>
            </ModalBody>
          </Modal>

        )
      }
      {
        openNextModal && (
          <Modal outerClick open={openNextModal}>
            <ModalBody width="600px" style={{ borderRadius: 0 }}>
              <ModalClose onClick={() => setOpenNextModal(false)}>&times;</ModalClose>
              <Box pad="30px">
                <h1>Set next section to go</h1>
                <Text>Set what section to go to when user is done with this section.</Text>
                <Spacer space="30px" />
                <Box>
                  <Box width="100%">
                    <Select
                      block
                      value={getSections().find(a => a.value === section.next)}
                      label="Next"
                      options={getSections()}
                      onChange={(v) => changeElementNext(v.value, section.id - 1)}
                    />
                  </Box>
                  <Spacer space="20px" />
                  <ModalFooter>
                      <Box pad="10px" align="right">
                        <Button
                          height={32}
                          onClick={() => setOpenNextModal(false)}
                          pale
                        >
                          Close
                        </Button>
                      </Box>
                  </ModalFooter>
                </Box>
              </Box>
            </ModalBody>
          </Modal>
        )
      }
    </SectionWrapper>
  );
};
