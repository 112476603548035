import request from './request';
import urls from './constants'

export function generateSchema(data,id) {
  const url = `${urls?.FORM_API_BASE_URL}/designs/${id}`;
  return request({
    method: 'put',
    data,
    url,
  });
}

export function getSchemaFromApi(id) {
  const url = `${urls?.FORM_API_BASE_URL}/designs/${id}`;
  return request({ method: 'get', url });
}
