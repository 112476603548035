import React from "react";
import { TextArea, Select, Switch, Checkbox, RadioButton, } from "@flexisaf/flexibull2";
import { Input, UploadComp, AutoComplete, SingleDate } from "./";
import { WebcamCapture } from './WebCam'
import { Editor } from '@tinymce/tinymce-react';
import ReactPhoneInput from "react-phone-input-2";
import { TinyMCEKey } from "../util/constants";
import 'react-phone-input-2/lib/style.css'

export const FormField = ({
  element,
  elementId,
  row,
  col,
  section,
  activeField,
  schema,
  handleCompoundChange
}: any) => {
  let props =
    schema.layout.sections[section - 1].rows[row - 1].cols[col - 1].props || {};
  if (!props.radio) {
    props.radio = [];
  }
  if (element.element === 'select' && !props.options) {
    props.options = [];
  }
  let Component;
  if (element.element === 'input' && element.type === 'plain') {
    Component = <Input spaceTop type='text' {...props} />
  }
  if (element.element === 'input' && element.type === 'number') {
    Component = <Input spaceTop type='number' {...props} />
  }
  if (element.element === 'input' && element.type === 'tel') {
    Component = <div>
      <label 
        style={{ height: '20px', display: 'block', fontWeight: 'bold', 
          textTransform: 'uppercase', fontSize: '9px' }}>
        {props.label}
      </label>
      <ReactPhoneInput
        containerStyle={{
          width: '100%'
        }}
        inputStyle={{
          width: '100%'
        }}
        country={"ng"}
        enableAreaCodes={false}
        {...props}
      />
    </div>
  }
  if (element.element === 'input' && element.type === 'email') {
    Component = <Input spaceTop type='email' {...props} />
  }
  if (element.element === 'input' && element.type === 'single-date') {
    Component = <SingleDate {...props} />
  }
  if (element.element === 'input' && element.type === 'date') {
    Component = <Input spaceTop type='date' {...props} />
  }
  if (element.element === 'input' && element.type === 'essay') {
    Component = <TextArea rows={1} height='auto' block spaceTop {...props} />
  }
  if (element.element === 'select' && element.type === 'simple') {
    Component = <Select spaceTop block {...props} />
  }
  if (element.element === 'checkbox' && element.type === 'checkbox') {
    Component = <Checkbox spaceTop='20px' block name={props.label} {...props} />
  }
  if (element.element === 'wysiwyg' && element.type === 'wysiwyg') {
    Component = <Editor
      init={{
        resize: false,
        height: 500,
        menubar: false,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'wordcount',
          'codesample',
          'code',
          'accordion',
          'pagebreak'
        ],
        toolbar:
          'casechange blocks fontfamily fontsizeinput bold italic underline accordion image strikethrough forecolor backcolor align lineheight bullist numlist outdent indent removeformat link table media pagebreak code',
        file_browser_callback_types: 'image',
        file_picker_callback: function (cb, value, meta) {
          if (meta.filetype === 'image') {
            var input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.click();
            input.onchange = function () {
              if (!input.files) return;
              var file = input.files[0];
              var reader = new FileReader();
              reader.onload = function (e) {
                if (!e.target) return;
                cb(JSON.stringify(e.target.result), {
                    alt: file.name
                });
              };
              reader.readAsDataURL(file);
            };
          }
        },
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
      apiKey={TinyMCEKey}
      name={props.label}
      {...props}
    />
  }
  if (element.element === 'switch' && element.type === 'switch') {
    Component = <Switch label={props.label} />
  }
  if (element.element === 'radio' && element.type === 'radio') {
    Component =
      props.radio &&
      <div style={{ display: "flex", flexDirection: "column" }}>
        {props.label}
        <div>
          {props?.radio.map((radio: any, index: any) => {
            return <RadioButton key={index} {...radio} name='test' />
          })}
        </div>
      </div>
  }
  if (element.element === 'location' && element.type === 'location') {
    Component = <AutoComplete {...props} />
  }
  if (element.element === 'lookup' && element.type === 'lookup') {
    Component = <Select onChange={(val) => handleCompoundChange(section - 1, row - 1, col - 1, val)} spaceTop block {...props} />;
  }
  if (element.element === 'camera' && element.type === 'camera') {
    Component = <WebcamCapture alternateLabel="Photo" {...props} />;
  }
  if (element.element === 'file' && element.type === 'file') {
    Component = (
      <UploadComp
        label={props?.label}
        action='/upload'
        multiple={true}
        name='James'
        style={{ display: 'block' }}
        component='div'
        alternateLabel="Attachment"
        {...props}
      />
    )
  }
  return <>{Component}</>;
};
