export default [
  {
    index: 15,
    icon: "form-sort-alphabet",
    label: "Text Field",
    color: "#0057ff",
    type: "plain",
    element: "input",
  },
  {
    index: 29,
    icon: "form-phone",
    label: "Phone",
    type: "tel",
    color: "#5D9C6A",
    element: "input",
  },
  {
    index: 16,
    icon: "form-sort-numeric",
    label: "Number",
    type: "number",
    color: "#5D9C5A",
    element: "input",
  },
  {
    index: 17,
    icon: "form-envelope-open-o",
    label: "Email",
    color: "#04A4BD",
    type: "email",
    element: "input",
  },
  {
    index: 18,
    icon: "form-calendar",
    label: "Date",
    type: "date",
    element: "input",
    color: "darkslategray",
  },
  {
    index: 24,
    icon: "form-doc-text",
    label: "TextArea",
    color: "#FDAB3A",
    type: "essay",
    element: "input",
  },
  {
    index: 19,
    icon: "form-sort",
    label: "Dropdown",
    color: "#F55C84",
    type: "simple",
    element: "select",
  },
  {
    index: 20,
    icon: "form-ok-circled2",
    label: "Checkbox",
    color: "#F55C84",
    type: "checkbox",
    element: "checkbox",
  },
  {
    index: 21,
    icon: "form-toggle-on",
    label: "Switch",
    color: "#0057ff",
    type: "switch",
    element: "switch",
  },
  {
    index: 22,
    icon: "form-dot-circled",
    label: "Radio",
    color: "#6155BB",
    type: "radio",
    element: "radio",
  },
  {
    index: 23,
    icon: "form-attach-1",
    label: "Upload",
    type: "file",
    element: "file",
    color: "darkkhaki",
  },
  {
    index: 26,
    icon: "form-globe",
    label: "Location",
    type: "location",
    element: "location",
    color: "crimson",
  },
  {
    index: 25,
    icon: "form-sitemap",
    label: "Datasource",
    type: "lookup",
    element: "lookup",
    color: "teal",
  },
  {
    index: 27,
    icon: "form-camera",
    label: "Image Capture",
    type: "camera",
    element: "camera",
    color: "tomato",
  },
  {
    index: 28,
    icon: "form-file-word",
    label: "WYSIWYG",
    type: "wysiwyg",
    element: "wysiwyg",
    color: "DarkKhaki",
  },
  {
    index: 30,
    icon: "form-calendar-1",
    label: "Single Date",
    type: "single-date",
    element: "input",
    color: "teal",
  },
];

// {
//   index: 26,
//   icon: "form-doc-inv-alt",
//   label: "Location",
//   color: "#777",
// },
// {
//   index: 25,
//   icon: "form-doc-inv-alt",
//   label: "Lookup",
//   color: "#777",
// },
